import React from 'react';
import { NavMenu, NavItem } from 're-cy-cle';
import { t } from '@code-yellow/spider';

export const renderCommunicationMenu = () => {
    return (
        <NavItem
            title={t('communication:nav.communication.label')}
            to="/communication/messagelog/overview"
            activePath="/communication"
        />
    );
};

export const renderCommunicationSubMenu = () => {
    return (
        <NavMenu data-test-menu-communication>
            <NavItem
                title={t('communication:nav.communication.messagelog')}
                to="/communication/messagelog/overview"
                activePath="/communication/messagelog"
            />
        </NavMenu>
    );
};

