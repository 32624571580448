import { afterSave } from '@code-yellow/spider';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trailer } from '../../store/Trailer';
import { TrailerEdit } from './../../container/Trailer/Edit';
import { Customer } from 'react-logistics-administration/src';

@observer
export default class TrailerEditScreen extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    @observable trailer = new Trailer({ id: this.props.match.params.id ? parseInt(this.props.match.params.id) : null },
        {
            relations: [
                'truckingCompany',
            ],
        }
    );

    truckingCompany = new Customer({
        id: this.props.match.params.truckCompanyId ? parseInt(this.props.match.params.truckCompanyId) : null,
    });

    async componentDidMount() {
        if (this.props.match.params.fleetNumber) {
            this.trailer = await this.trailer.getByFleetNumber(this.props.match.params.fleetNumber);
        } else if (this.trailer.id) {
            this.trailer.fetch();
        }

        if (this.truckingCompany.id) {
            this.trailer.truckingCompany = this.truckingCompany;
        }
    }

    createNewTrailer = action(() => {
        this.trailer.clear();
    })

    render() {
        return (
            <TrailerEdit
                trailer={this.trailer}
                afterSave={afterSave(this.props.history, this.props.viewStore, this.trailer)}
                createNewTrailer={this.createNewTrailer}
                match={this.props.match}
                history={this.props.history}
                viewStore={this.props.viewStore}
            />
        );
    }
}
