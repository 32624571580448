import Load from '../../../container/Loadable';

const TrucksOverview = Load(() => import('../screen/Trucks/Overview'));

export const TrucksOverviewConfig = {
    path: '/customer-portal/trucks',
    component: TrucksOverview,
}

export const customerPortalRoutesConfig = [
    TrucksOverviewConfig,
];