import { View } from '@code-yellow/spider';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route as BaseRoute, Redirect, Switch } from 'react-router-dom';
import Load from './Loadable';

import { metabaseRoutesConfig } from '@code-yellow/core-metabase';
import { celerymonitorRoutesConfig } from '@code-yellow/core-monitoring';
import { financeRoutesConfig } from 'react-logistics-finance/src'; // [TODO] export to module '@code-yellow/logistics-administration'
import { administrationRoutesConfig } from 'react-logistics-administration/src/index'; // [TODO] export to module '@code-yellow/logistics-administration'
import { masterDataRoutesConfig } from 'react-logistics-masterdata/src/index'; // [TODO] export to module '@code-yellow/logistics-masterdata'
import { planningRoutesConfig } from 'react-logistics-planning/src/index'; // [TODO] export to module '@code-yellow/logistics-planning'
import { communicationRoutesConfig } from 'react-core-communication/src/index'; // [TODO] export to module '@code-yellow/core-communication'
import { ActivityTemplateOverviewConfig } from 'react-logistics-driverapp/src/index'; // [TODO] export to module '@code-yellow/logistics-driverapp'
import { customerPortalRoutesConfig } from 'react-logistics-customerportal/src/index'

import { ACTIVITY_TYPES } from 'react-logistics-administration/src/store/enums/ActivityType';
import { isDriverUser } from 'helpers/currentUser';

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const LoginForgot = Load(async () => ((await import('@code-yellow/spider')).LoginForgot));
const ResetPassword = Load(async () => ((await import('@code-yellow/spider')).ResetPassword));
const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));
const DriverApp = Load(() => import('../screen/DriverApp'));

const NotFound = Load(() => import('../container/NotFound'));
const NotAllowed = Load(() => import('../container/NotAllowed'));


// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
        // moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired
    };

    // react-router is a bit too verbose so I made a shorthand
    route = (Screen, componentProps) => {
        return rProps => <Screen {...rProps} {...componentProps} viewStore={this.props.store} />;
    };

    redirectFromHome = () => {
        const { currentUser } = this.props.store;
        let uri = currentUser.isSuperuser ? (
            '/account/user/overview'
        ) : (
            '/account/details'
        );

        return <Redirect to={uri} />;
    };

    renderModulesRoutes(...modulesConfigs) {
        const flatConfig = [].concat(...modulesConfigs);

        return flatConfig.map((route, i) => (
            <Route
                key={route.component} // Keying by index remounts entire route when switching between routes with the same component. This approach is not valid with how spider works thats why we key by component.
                path={route.path}
                render={this.route(route.component)}
                // render={this.route(route.component, route.componentProps)} IOC should happen here and inject needed dependencies of the module / route ??
            />
        ))

    }

    renderDriverAppSection = () => {
        const items = ACTIVITY_TYPES.reduce(function(map, item) {
            map[t(`administration:activity.types.${item}`)] = item;
            return map;
        }, {});

        const storesList = [
            { title: t('administration:trip.modal.subcontract.truckLicensePlate'), field: 'cy_logistics_masterdata.truck.license_plate' },
            { title: t('administration:trip.modal.subcontract.trailerLicensePlate'), field: 'cy_logistics_masterdata.trailer.license_plate' },
        ];

        return (
            <Route
                key={ActivityTemplateOverviewConfig.component} // Keying by index remounts entire route when switching between routes with the same component. This approach is not valid with how spider works thats why we key by component.
                path={ActivityTemplateOverviewConfig.path}
                render={this.route(ActivityTemplateOverviewConfig.component, { items:items, lists:storesList })}
            />
        );
    }

    render() {
        if (isDriverUser()) {
            return (
                <Route render={this.route(NotAllowed)} />
            )
        }

        return (
            <Switch>
                <Route path="/" exact render={this.redirectFromHome} />
                <Route path="/account/details" render={this.route(AccountDetails)} />
                <Route path="/account/user/overview" render={this.route(UserOverview)} />

                <Route path="/account/user/:id/edit" render={this.route(UserEdit)} />
                <Route path="/account/user/add" render={this.route(UserEdit)} />
                <Route path="/account/user/email-log/overview" render={this.route(EmailLogOverview)} />

                <Route path="/account/global-value/overview" render={this.route(GlobalValueOverview)} />
                <Route path="/account/global-value/:id/edit" render={this.route(GlobalValueEdit)} />

                <Route path="/account/global-file/overview" render={this.route(GlobalFileOverview)} />
                <Route path="/account/global-file/:id/edit" render={this.route(GlobalFileEdit)} />

                <Route path="/account/access-log/overview" render={this.route(AccessLogEntryOverview)} />

                <Route path="/account/changelog" render={this.route(Changelog)} />

                <Route path="/login/forgot" render={this.route(LoginForgot)} />
                <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)} />
                <Route path="/activate" render={this.route(DriverApp)} />
                {this.renderModulesRoutes(
                    metabaseRoutesConfig,
                    celerymonitorRoutesConfig,
                    planningRoutesConfig,
                    administrationRoutesConfig,
                    masterDataRoutesConfig,
                    financeRoutesConfig,
                    communicationRoutesConfig,
                    customerPortalRoutesConfig,
                )}
                {this.renderDriverAppSection()}
                <Route path="*" render={this.route(NotFound)} />
            </Switch>
        );
    }
}
