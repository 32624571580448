import Load from './../../../container/Loadable';

const PlanningActivityOverviewScreen = Load(() => import('../screen/PlanningActivity/Overview'));
const OperationsOverviewScreen = Load(() => import('../screen/Operations/Overview'));
const ForwardingOverviewScreen = Load(() => import('../screen/Forwarding/Overview'));
const MapsOverviewScreen = Load(() => import('../screen/Map/Overview'));

export const planningRoutesConfig = [
    { path: '/planning/activity/overview', component: PlanningActivityOverviewScreen },
    { path: '/planning/operations/', component: OperationsOverviewScreen },
    { path: '/planning/forwarding/', component: ForwardingOverviewScreen },
    { path: '/planning/maps/', component: MapsOverviewScreen },
];
