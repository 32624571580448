import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import App from './container/App';
import { ViewStore } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { theme } from './styles.ts';
// import i18next from 'i18next';
// use router instead of browserRouter for sentry, see
// https://github.com/getsentry/sentry-javascript/issues/3107#issuecomment-741431381
// https://github.com/getsentry/sentry-javascript/issues/3107#issuecomment-741431381
import { Router } from 'react-router-dom';
import { ReCyCleTheme } from 're-cy-cle';
import * as Sentry from '@sentry/react';
import configureSentry, { setUserSentry } from './sentry';
import { PUBLIC_URL, FRONTEND_API_BASE_URL, configOverride } from 'helpers';
import { configureModal } from 'helpers/modal';
import { configureCurrentUser } from 'helpers/currentUser';
import { Modal } from 'semantic-ui-react';
import 'moment-duration-format';
import { User } from 'store/User';
import { configureTranslation } from 'daycy';
import { createBrowserHistory } from 'history';
import { api, i18next, ApplyButton, CancelButton, RightDivider, configureAfterSave, configureBasename, configurePermission, t, configureNotification } from '@code-yellow/spider';
import Background from 'image/login_image.jpg';
import { configureDateLib } from 'mobx-spine';

import 'daycy/dist/daycy.css';
import '@code-yellow/spider/dist/spider.css'
import 'style/semantic-ui.css';
import 'style/extra-icons.css';
import 'style/semantic-ui/foo/bar/main.css';
import 'style/semantic-ui/daycy.css';
import 'style/custom-icons/cy-custom-icons.css';
import 'style/colors.css';

import { MetabaseSettings } from '@code-yellow/core-metabase'
import { MasterDataSettings } from 'react-logistics-masterdata/src/index' //'@code-yellow/logistics-masterdata'
import { AdministrationSettings } from 'react-logistics-administration/src/index' //'@code-yellow/logistics-masterdata'


// Translations
import { initTranslations } from './i18n';

initTranslations();

window.t = t;
window.i18next = i18next;

configureTranslation((key, args) => {
    return t(`daycy.${key}`, args);
});

const setupModules = (res) => {
    MetabaseSettings.setupModule(res)
    MasterDataSettings.setupModule(res)
    AdministrationSettings.setupModule(res)
}

export class SpecialViewStore extends ViewStore {
    background = Background;

    background = Background;

    @action
    handleBootstrap(res) {
        configOverride(res);
        setupModules(res);
        return super.handleBootstrap(res);
    }

    parseCurrentUserFromBootstrap(res) {
        this.currentUser.fromBackend({
            data: res.user.data,
            repos: res.user.with,
            relMapping: res.user.with_mapping,
        });
        setUserSentry(this.currentUser)
    }

    // TODO: Move this to spider if its working, if its not then remove it
    @observable defaultNotificationDismissTime = 4000;
    setDefaultNotificationDismissTime(value) {
        this.defaultNotificationDismissTime = value;
    }

    // TODO: Move this to spider if its working, if its not then remove it
    @action
    showSaveNotification() {
        this.showNotification({
            key: 'requestSave',
            dismissAfter: this.defaultNotificationDismissTime,
            message: t('form.notifications.saveSuccess'),
        });
    }
}

api.baseUrl = FRONTEND_API_BASE_URL;
const viewStore = new SpecialViewStore({
    api,
    user: new User(null, {
        relations: ['groups.permissions',  'metabases', 'groups.metabases']
    }),
    socketUrl: `${PUBLIC_URL || ''}/ws/`,
});

// TODO: Move this to incenova if its working, if its not then remove it
if(process.env.REACT_APP_DEFAULT_NOTIFICATION_DISMISS_TIME) {
    const dismissTime = parseInt(process.env.REACT_APP_DEFAULT_NOTIFICATION_DISMISS_TIME);
    viewStore.setDefaultNotificationDismissTime(isNaN(dismissTime) ? false : dismissTime);
}

/**
 * Currently test newAppVersionNotification abuses the fact that viewStore is
 * globally available. We should only expose this when debug = true. BOEK has
 * a debug mode, where you can see more in the interface (like calculations)
 * and have access to scary buttons.
 */
window.viewStore = viewStore;

export const history = createBrowserHistory();
configureSentry(viewStore, history);

configureModal(viewStore);
configureNotification(viewStore);
configurePermission(viewStore);
configureBasename(PUBLIC_URL);
configureAfterSave({ goBack: false, createUrl: '/add' });
configureCurrentUser(viewStore);
configureDateLib('luxon');

@observer
class Root extends Component {
    @observable showAlert = false;
    @observable alertMessage = '';
    @observable alertConfirm = null;

    // Custom alert callbacks.
    @observable alertOnApply = null;
    @observable alertOnCancel = null;

    componentDidMount() {
        i18next.on('languageChanged', () => this.forceUpdate());
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }

    cancel = () => {
        this.showAlert = false;

        if (this.alertOnCancel) {
            this.alertOnCancel();
        }

        this.alertConfirm(false);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    confirm = () => {
        this.showAlert = false;

        if (this.alertOnApply) {
            this.alertOnApply();
        }

        this.alertConfirm(true);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    render() {
        return (
            <Sentry.ErrorBoundary showDialog>
                <React.Fragment key={i18next.language}>
                    <Modal size="tiny" open={this.showAlert} centered={false}>
                        <Modal.Content style={{ textAlign: 'center' }}>
                            <p>{this.alertMessage}</p>
                            <p>{t('form.confirmQuestion')}</p>
                        </Modal.Content>
                        <Modal.Actions style={{ display: 'flex' }}>
                            <CancelButton negative onClick={this.cancel} />
                            <RightDivider />
                            <ApplyButton positive onClick={this.confirm} content={t('form.continueButton')} />
                        </Modal.Actions>
                    </Modal>
                    <ReCyCleTheme theme={theme}>
                        <Router history={history} basename={PUBLIC_URL} getUserConfirmation={(message, confirm, ...args) => {
                            this.showAlert = true;
                            this.alertConfirm = confirm;

                            if (typeof message === 'object') {
                                this.alertMessage = message.message;
                                this.alertOnApply = message.onApply;
                                this.alertOnCancel = message.onCancel;
                            } else {
                                this.alertMessage = message;
                                this.alertOnApply = null;
                                this.alertOnCancel = null;
                            }

                        }}>
                            <App store={viewStore} history={history} />
                        </Router>
                    </ReCyCleTheme>
                </React.Fragment>
            </Sentry.ErrorBoundary>
        );
    }
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);
