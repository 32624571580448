import { observable } from 'mobx';
import { ContactStore } from './Contact';
import { CustomerLocationStore } from './CustomerLocation';
import { Customer as CustomerBase, CustomerStore as CustomerStoreBase } from 'react-core-administration/src';
import { DossierStore } from './Dossier';
import { SelfBillItemStore } from 'react-logistics-finance/src/store/SelfBillItem';
import { Ledger } from 'react-logistics-finance/src/store/Ledger';
import { TrailerStore, TruckStore } from 'react-logistics-masterdata/src';
import { TripStore } from './Trip';

export class Customer extends CustomerBase {
    static backendResourceName = 'logistics_customer';
    static omitFields = [...CustomerBase.omitFields, 'invoiceableItemsCount'];

    @observable selfBilling = false;
    @observable forceAutofinalizeActivities = false;

    @observable contacts = this.relation(ContactStore);
    @observable locations = this.relation(CustomerLocationStore);
    @observable dossiers = this.relation(DossierStore);
    @observable trucks = this.relation(TruckStore);
    @observable trailers = this.relation(TrailerStore);
    @observable trips = this.relation(TripStore);
    @observable selfBillItems = this.relation(SelfBillItemStore);
    @observable defaultLedger = this.relation(Ledger);

    @observable invoiceableItemsCount = 0;
}

export class CustomerStore extends CustomerStoreBase<Customer> {
    static backendResourceName = 'logistics_customer';
    Model = Customer;
}
