import { Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { Entity } from 'react-core-administration/src/store/Entity';

export class Ledger extends Model {
    static backendResourceName = 'ledger';

    @observable entity = this.relation(Entity);

    @observable id = null;
    @observable number = '';
    @observable vatBalanceNumber = '';
    @observable description = ''
    @observable vatCode = '' // Example: ICP, VH
    @observable vatName = '' // Example: Geen, VAT 0%
    @observable vatPercentage = 0 // Example: 0%, 21%
    @observable deleted = false

    casts() {
        return {
        };
    }
}

export class LedgerStore extends Store<Ledger> {
    Model = Ledger;
    static backendResourceName = 'ledger';
}
