import { t, SaveButton, showErrorNotification } from '@code-yellow/spider';
import AdminEditScreen from 'component/AdminEditScreen'
import { observer } from 'mobx-react';
import { Subheading } from 're-cy-cle';
import React from 'react';
import { Truck } from '../../store/Truck';
import { ExternalDataSourceList } from '../../store/enums/ExternalDataSource';
import ActiveButtons from 'react-logistics-masterdata/src/component/ActiveButtons';
import styled from 'styled-components';
import { formatInputToUppercase, validateLicensePlateInput } from 'react-core-administration/src/helpers';
import { CustomerStore } from 'react-logistics-administration/src';

export const FormLabel = styled.label`
    display: flex !important;
    align-items: center;
`;

type TruckEditProps = {
    truck: Truck,
    createNewTruck: () => void,
    afterSave: () => void,
    isModal?: boolean
}

@observer
export class TruckEdit extends AdminEditScreen {
    static baseUrl = '/master-data/truck/';

    props: TruckEditProps;

    isModal = this.props?.isModal ?? false;

    constructor(props) {
        super(props);
        this.renderHeader = this.renderHeader.bind(this);
    }

    Model = Truck;
    relations = ['truckingCompany'];
    saveRelations = [];
    truckingCompanyStore = new CustomerStore();

    componentDidMount() {
        super.componentDidMount();

        this.model = this.props.truck;
    }

    renderTitle() {
        return this.model && this.model.fleetNumber
            ? t('masterData:truck.edit.title', { id: this.model.fleetNumber })
            : t('masterData:truck.create.title')
    }

    renderButtonActive = (truck: Truck) => (
        <ActiveButtons
            asset={truck}
            renderType='standard'
            label={t('masterData:truck.field.active.label')}
        />
    )

    save = ({ createNew = false, saveAndBack = false }) => {
        const { afterSave } = this.props;
        return this.model
            .save({
                onlyChanges: true,
                relations:  ['truckingCompany'],
            })
            .then(() => {
                const afterSaveProps = {
                    goBack: false
                };
                if (saveAndBack){
                    afterSaveProps.goTo = '/master-data/truck/overview';
                    afterSaveProps.goBack = saveAndBack;
                }
                if(createNew){
                    afterSaveProps.goTo = '/master-data/truck/add';
                    afterSaveProps.goBack = createNew;
                    this.model.clear();
                }
                afterSave(afterSaveProps)
            })
            .catch((err) => {
                const errorCode = err.response?.data?.errors?.truck?.fleet_number?.[0]?.code;
                if (errorCode === 'existing_fleet_number') {
                    showErrorNotification(t('masterData:truck.edit.errorMessage.fleetNumber'));
                } else {
                    // Handle other types of errors if needed
                }
            });
    };

    renderRightButtons() {
        return <>
                <SaveButton primary compact
                    onClick={() => this.save({ saveAndBack: true })}
                    content={t('masterData:form.saveAndBack')}
                    loading={this.model.isLoading}
                />
                <SaveButton primary compact
                    onClick={() => this.save({ createNew: true })}
                    content={t('masterData:form.saveCreateEmpty')}
                    loading={this.model.isLoading}
                />
                <SaveButton
                    primary
                    compact
                    data-test-save-same-truck-button
                    loading={this.model.isLoading}
                    onClick={this.save}
                />
        </>
    }

    fields = [
        {
            type: 'custom',
            callback: () => (
                <Subheading>{t('masterData:truck.edit.basicInfoTitle')}</Subheading>
            ),
        },
        (truck) => ({ type: 'group', widths: 'equal', fields: [
            {
                type: 'text',
                name: 'fleetNumber',
                label: t('masterData:truck.field.fleetNumber.label'),
                required: true,
            },
            {
                type: 'text',
                name: 'brandType',
                label: t('masterData:truck.field.brandType.label'),
                required: true,
            },
            {
                type: 'text',
                name: 'licensePlate',
                label: t('masterData:truck.field.licensePlate.label'),
                required: true,
                onKeyPress: validateLicensePlateInput,
                onBlur: (event: Event) => formatInputToUppercase(event, truck.licensePlate, truck, 'licensePlate')
            },
        ] }),
        { type: 'group', widths: 'equal', fields: [
            {
                type: 'select',
                name: 'dataSource',
                label: t('masterData:truck.field.dataSource.label'),
                options: ExternalDataSourceList.map(item => ({
                    value: item.toString(),
                    text: item,
                }))
            },
            {
                type: 'text',
                name: 'dataReference',
                label: t('masterData:truck.field.dataReference.label'),
                autoComplete: 'off',
            },
        ] },
        { type: 'group', widths: 'equal', fields: [
            {
                type: 'date',
                name: 'nextApk',
                clearable: true,
                label: t('masterData:truck.field.nextApk.label'),
                autoComplete: 'off',
            },
            {
                type: 'date',
                name: 'nextTacho',
                clearable: true,
                label: t('masterData:truck.field.nextTacho.label'),
                autoComplete: 'off',
            },
            {
                type: 'number',
                name: 'mileage',
                label: t('masterData:truck.field.mileage.label'),
                autoComplete: 'off',
                textAlign: 'left',
            },
        ] },
        { type: 'group', widths: 'equal', fields: [
            {
                type: 'select',
                name: 'truckingCompany',
                label: t('masterData:truck.field.truckingCompany.label'),
                store: this.truckingCompanyStore,
                remote: true,
                clearable: true,
                required: true,
                searchKey: '.name:icontains',
                toOption: truckingCompany => ({
                    value: truckingCompany.id,
                    text: truckingCompany.name,
                }),
                props: {
                    'data-test-owner': 'true',
                },
            },
            {
                type: 'number',
                name: 'fuelCapacity',
                label: t('masterData:truck.field.fuelCapacity.label'),
                textAlign: 'left',
            },
        ] },
        {
            type: 'bool',
            name: 'wabco',
            label: t('masterData:truck.field.wabco.label'),
        },
        {
            type: 'custom',
            callback: () => (
                <Subheading style={{ marginTop: '32px' }}>{t('masterData:truck.edit.permitInfoTitle')}</Subheading>
            ),
        },
        { type: 'group', widths: 'equal', fields: [
            {
                type: 'text',
                name: 'permit',
                label: t('masterData:truck.field.permit.label'),
            },
            {
                type: 'date',
                clearable: true,
                name: 'permitExpirationDate',
                label: t('masterData:truck.field.permitExpirationDate.label'),
                autoComplete: 'off',
            },
        ] },
        {
            type: 'text',
            name: 'waPermit',
            label: t('masterData:truck.field.waPermit.label'),
            // Sublabel to explain the required formatting. This field is not validated to be a valid
            // date since it's of type string (should also allow strings like "Casco"). The consequences
            // of saving an invalid date is unknown
            subLabel: 'Use format YYYY-MM-DD (e.g. 2024-04-19)',
        },
        {
            type: 'custom',
            callback: () => (
                <Subheading style={{ marginTop: '32px' }}>{t('masterData:truck.edit.specsTitle')}</Subheading>
            ),
        },
        {
            type: 'textArea',
            name: 'remarks',
            label: t('masterData:truck.field.remarks.label'),
        },
        {
            type: 'textArea',
            name: 'truckInventory',
            label: t('masterData:truck.field.truckInventory.label'),
        },
        {
            type: 'date',
            name: 'soldAt',
            label: t('masterData:truck.field.soldAt.label'),
            clearable: true,
        },
        {
            type: 'custom',
            callback: this.renderButtonActive,
        },
    ]
}
