import { Model, Store, Casts } from '@code-yellow/spider';
import { observable } from 'mobx';
import { DriverStore } from './Driver';

export class DriverReference extends Model {
    static backendResourceName = 'driver_reference';
    static omitFields = ['routesCount'];

    @observable id = null;
    @observable type = 'transics';
    @observable dataReference = '';
    @observable validFrom = null;
    @observable validTo = null;

    @observable driver = this.relation(DriverStore);

    casts() {
        return {
            validFrom: Casts.datetime,
            validTo: Casts.datetime,
        };
    }
}

export class DriverReferenceStore extends Store<DriverReference> {
    Model = DriverReference;
    static backendResourceName = 'driver_reference';
}
