import { sortBy, uniqBy } from 'lodash';

/**
 * Hey there!
 *
 * The file below is a copy-paste from Spider, to extend and quickly implement a
 * proper country select, preventing further pollution of the customers table.
 * This functionality should be ported back to Spider, after which this project
 * should be updated to the newest Spider version. As soon as this is done,
 * please refactor the TargetCountrySelect and remove this file.
 */

export const countries = [
    {
        country: 'AFGHANISTAN',
        currency: 'Afghani',
        code: 'AFN',
        number: '971',
    },
    {
        country: 'ALBANIA',
        currency: 'Lek',
        code: 'ALL',
        number: '008',
    },
    {
        country: 'ALGERIA',
        currency: 'Algerian Dinar',
        code: 'DZD',
        number: '012',
    },
    {
        country: 'AMERICAN SAMOA',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'ANDORRA',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'ANGOLA',
        currency: 'Kwanza',
        code: 'AOA',
        number: '973',
    },
    {
        country: 'ANGUILLA',
        currency: 'East Caribbean Dollar',
        code: 'XCD',
        number: '951',
    },
    {
        country: 'ANTARCTICA',
        currency: 'No universal currency',
        code: ' ',
        number: ','
    },
    {
        country: 'ANTIGUA AND BARBUDA',
        currency: 'East Caribbean Dollar',
        code: 'XCD',
        number: '951',
    },
    {
        country: 'ARGENTINA',
        currency: 'Argentine Peso',
        code: 'ARS',
        number: '032',
    },
    {
        country: 'ARMENIA',
        currency: 'Armenian Dram',
        code: 'AMD',
        number: '051',
    },
    {
        country: 'ARUBA',
        currency: 'Aruban Florin',
        code: 'AWG',
        number: '533',
    },
    {
        country: 'AUSTRALIA',
        currency: 'Australian Dollar',
        code: 'AUD',
        number: '036',
    },
    {
        country: 'AUSTRIA',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'AZERBAIJAN',
        currency: 'Azerbaijanian Manat',
        code: 'AZN',
        number: '944',
    },
    {
        country: 'BAHAMAS (THE)',
        currency: 'Bahamian Dollar',
        code: 'BSD',
        number: '044',
    },
    {
        country: 'BAHRAIN',
        currency: 'Bahraini Dinar',
        code: 'BHD',
        number: '048',
    },
    {
        country: 'BANGLADESH',
        currency: 'Taka',
        code: 'BDT',
        number: '050',
    },
    {
        country: 'BARBADOS',
        currency: 'Barbados Dollar',
        code: 'BBD',
        number: '052',
    },
    {
        country: 'BELARUS',
        currency: 'Belarussian Ruble',
        code: 'BYN',
        number: '933',
    },
    {
        country: 'BELGIUM',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'BELIZE',
        currency: 'Belize Dollar',
        code: 'BZD',
        number: '084',
    },
    {
        country: 'BENIN',
        currency: 'CFA Franc BCEAO',
        code: 'XOF',
        number: '952',
    },
    {
        country: 'BERMUDA',
        currency: 'Bermudian Dollar',
        code: 'BMD',
        number: '060',
    },
    {
        country: 'BHUTAN',
        currency: 'Ngultrum',
        code: 'BTN',
        number: '064',
    },
    {
        country: 'BHUTAN',
        currency: 'Indian Rupee',
        code: 'INR',
        number: '356',
    },
    {
        country: 'BOLIVIA (PLURINATIONAL STATE OF)',
        currency: 'Boliviano',
        code: 'BOB',
        number: '068',
    },
    {
        country: 'BOLIVIA (PLURINATIONAL STATE OF)',
        currency: 'Mvdol',
        code: 'BOV',
        number: '984',
    },
    {
        country: 'BONAIRE, SINT EUSTATIUS AND SABA',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'BOSNIA AND HERZEGOVINA',
        currency: 'Convertible Mark',
        code: 'BAM',
        number: '977',
    },
    {
        country: 'BOTSWANA',
        currency: 'Pula',
        code: 'BWP',
        number: '072',
    },
    {
        country: 'BOUVET ISLAND',
        currency: 'Norwegian Krone',
        code: 'NOK',
        number: '578',
    },
    {
        country: 'BRAZIL',
        currency: 'Brazilian Real',
        code: 'BRL',
        number: '986',
    },
    {
        country: 'BRITISH INDIAN OCEAN TERRITORY (THE)',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'BRUNEI DARUSSALAM',
        currency: 'Brunei Dollar',
        code: 'BND',
        number: '096',
    },
    {
        country: 'BULGARIA',
        currency: 'Bulgarian Lev',
        code: 'BGN',
        number: '975',
    },
    {
        country: 'BURKINA FASO',
        currency: 'CFA Franc BCEAO',
        code: 'XOF',
        number: '952',
    },
    {
        country: 'BURUNDI',
        currency: 'Burundi Franc',
        code: 'BIF',
        number: '108',
    },
    {
        country: 'CABO VERDE',
        currency: 'Cabo Verde Escudo',
        code: 'CVE',
        number: '132',
    },
    {
        country: 'CAMBODIA',
        currency: 'Riel',
        code: 'KHR',
        number: '116',
    },
    {
        country: 'CAMEROON',
        currency: 'CFA Franc BEAC',
        code: 'XAF',
        number: '950',
    },
    {
        country: 'CANADA',
        currency: 'Canadian Dollar',
        code: 'CAD',
        number: '124',
    },
    {
        country: 'CAYMAN ISLANDS (THE)',
        currency: 'Cayman Islands Dollar',
        code: 'KYD',
        number: '136',
    },
    {
        country: 'CENTRAL AFRICAN REPUBLIC (THE)',
        currency: 'CFA Franc BEAC',
        code: 'XAF',
        number: '950',
    },
    {
        country: 'CHAD',
        currency: 'CFA Franc BEAC',
        code: 'XAF',
        number: '950',
    },
    {
        country: 'CHILE',
        currency: 'Unidad de Fomento',
        code: 'CLF',
        number: '990',
    },
    {
        country: 'CHILE',
        currency: 'Chilean Peso',
        code: 'CLP',
        number: '152',
    },
    {
        country: 'CHINA',
        currency: 'Yuan Renminbi',
        code: 'CNY',
        number: '156',
    },
    {
        country: 'CHRISTMAS ISLAND',
        currency: 'Australian Dollar',
        code: 'AUD',
        number: '036',
    },
    {
        country: 'COCOS (KEELING) ISLANDS (THE)',
        currency: 'Australian Dollar',
        code: 'AUD',
        number: '036',
    },
    {
        country: 'COLOMBIA',
        currency: 'Colombian Peso',
        code: 'COP',
        number: '170',
    },
    {
        country: 'COLOMBIA',
        currency: 'Unidad de Valor Real',
        code: 'COU',
        number: '970',
    },
    {
        country: 'COMOROS (THE)',
        currency: 'Comoro Franc',
        code: 'KMF',
        number: '174',
    },
    {
        country: 'CONGO (THE DEMOCRATIC REPUBLIC OF THE)',
        currency: 'Congolese Franc',
        code: 'CDF',
        number: '976',
    },
    {
        country: 'CONGO (THE)',
        currency: 'CFA Franc BEAC',
        code: 'XAF',
        number: '950',
    },
    {
        country: 'COOK ISLANDS (THE)',
        currency: 'New Zealand Dollar',
        code: 'NZD',
        number: '554',
    },
    {
        country: 'COSTA RICA',
        currency: 'Costa Rican Colon',
        code: 'CRC',
        number: '188',
    },
    {
        country: 'CROATIA',
        currency: 'Kuna',
        code: 'HRK',
        number: '191',
    },
    {
        country: 'CUBA',
        currency: 'Peso Convertible',
        code: 'CUC',
        number: '931',
    },
    {
        country: 'CUBA',
        currency: 'Cuban Peso',
        code: 'CUP',
        number: '192',
    },
    {
        country: 'CURAÇAO',
        currency: 'Netherlands Antillean Guilder',
        code: 'ANG',
        number: '532',
    },
    {
        country: 'CYPRUS',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'CZECH REPUBLIC (THE)',
        currency: 'Czech Koruna',
        code: 'CZK',
        number: '203',
    },
    {
        country: 'CÔTE D\'IVOIRE',
        currency: 'CFA Franc BCEAO',
        code: 'XOF',
        number: '952',
    },
    {
        country: 'DENMARK',
        currency: 'Danish Krone',
        code: 'DKK',
        number: '208',
    },
    {
        country: 'DJIBOUTI',
        currency: 'Djibouti Franc',
        code: 'DJF',
        number: '262',
    },
    {
        country: 'DOMINICA',
        currency: 'East Caribbean Dollar',
        code: 'XCD',
        number: '951',
    },
    {
        country: 'DOMINICAN REPUBLIC (THE)',
        currency: 'Dominican Peso',
        code: 'DOP',
        number: '214',
    },
    {
        country: 'ECUADOR',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'EGYPT',
        currency: 'Egyptian Pound',
        code: 'EGP',
        number: '818',
    },
    {
        country: 'EL SALVADOR',
        currency: 'El Salvador Colon',
        code: 'SVC',
        number: '222',
    },
    {
        country: 'EL SALVADOR',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'EQUATORIAL GUINEA',
        currency: 'CFA Franc BEAC',
        code: 'XAF',
        number: '950',
    },
    {
        country: 'ERITREA',
        currency: 'Nakfa',
        code: 'ERN',
        number: '232',
    },
    {
        country: 'ESTONIA',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'ETHIOPIA',
        currency: 'Ethiopian Birr',
        code: 'ETB',
        number: '230',
    },
    {
        country: 'EUROPEAN UNION',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'FALKLAND ISLANDS (THE) [MALVINAS]',
        currency: 'Falkland Islands Pound',
        code: 'FKP',
        number: '238',
    },
    {
        country: 'FAROE ISLANDS (THE)',
        currency: 'Danish Krone',
        code: 'DKK',
        number: '208',
    },
    {
        country: 'FIJI',
        currency: 'Fiji Dollar',
        code: 'FJD',
        number: '242',
    },
    {
        country: 'FINLAND',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'FRANCE',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'FRENCH GUIANA',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'FRENCH POLYNESIA',
        currency: 'CFP Franc',
        code: 'XPF',
        number: '953',
    },
    {
        country: 'FRENCH SOUTHERN TERRITORIES (THE)',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'GABON',
        currency: 'CFA Franc BEAC',
        code: 'XAF',
        number: '950',
    },
    {
        country: 'GAMBIA (THE)',
        currency: 'Dalasi',
        code: 'GMD',
        number: '270',
    },
    {
        country: 'GEORGIA',
        currency: 'Lari',
        code: 'GEL',
        number: '981',
    },
    {
        country: 'GERMANY',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'GHANA',
        currency: 'Ghana Cedi',
        code: 'GHS',
        number: '936',
    },
    {
        country: 'GIBRALTAR',
        currency: 'Gibraltar Pound',
        code: 'GIP',
        number: '292',
    },
    {
        country: 'GREECE',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'GREENLAND',
        currency: 'Danish Krone',
        code: 'DKK',
        number: '208',
    },
    {
        country: 'GRENADA',
        currency: 'East Caribbean Dollar',
        code: 'XCD',
        number: '951',
    },
    {
        country: 'GUADELOUPE',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'GUAM',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'GUATEMALA',
        currency: 'Quetzal',
        code: 'GTQ',
        number: '320',
    },
    {
        country: 'GUINEA',
        currency: 'Guinea Franc',
        code: 'GNF',
        number: '324',
    },
    {
        country: 'GUINEA-BISSAU',
        currency: 'CFA Franc BCEAO',
        code: 'XOF',
        number: '952',
    },
    {
        country: 'GUYANA',
        currency: 'Guyana Dollar',
        code: 'GYD',
        number: '328',
    },
    {
        country: 'HAITI',
        currency: 'Gourde',
        code: 'HTG',
        number: '332',
    },
    {
        country: 'HAITI',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'HEARD ISLAND AND McDONALD ISLANDS',
        currency: 'Australian Dollar',
        code: 'AUD',
        number: '036',
    },
    {
        country: 'HOLY SEE (THE)',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'HONDURAS',
        currency: 'Lempira',
        code: 'HNL',
        number: '340',
    },
    {
        country: 'HONG KONG',
        currency: 'Hong Kong Dollar',
        code: 'HKD',
        number: '344',
    },
    {
        country: 'HUNGARY',
        currency: 'Forint',
        code: 'HUF',
        number: '348',
    },
    {
        country: 'ICELAND',
        currency: 'Iceland Krona',
        code: 'ISK',
        number: '352',
    },
    {
        country: 'INDIA',
        currency: 'Indian Rupee',
        code: 'INR',
        number: '356',
    },
    {
        country: 'INDONESIA',
        currency: 'Rupiah',
        code: 'IDR',
        number: '360',
    },
    {
        country: 'INTERNATIONAL MONETARY FUND (IMF)',
        currency: 'SDR (Special Drawing Right)',
        code: 'XDR',
        number: '960',
    },
    {
        country: 'IRAN (ISLAMIC REPUBLIC OF)',
        currency: 'Iranian Rial',
        code: 'IRR',
        number: '364',
    },
    {
        country: 'IRAQ',
        currency: 'Iraqi Dinar',
        code: 'IQD',
        number: '368',
    },
    {
        country: 'IRELAND',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'ISLE OF MAN',
        currency: 'Pound Sterling',
        code: 'GBP',
        number: '826',
    },
    {
        country: 'ISRAEL',
        currency: 'New Israeli Sheqel',
        code: 'ILS',
        number: '376',
    },
    {
        country: 'ITALY',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'JAMAICA',
        currency: 'Jamaican Dollar',
        code: 'JMD',
        number: '388',
    },
    {
        country: 'JAPAN',
        currency: 'Yen',
        code: 'JPY',
        number: '392',
    },
    {
        country: 'JERSEY',
        currency: 'Pound Sterling',
        code: 'GBP',
        number: '826',
    },
    {
        country: 'JORDAN',
        currency: 'Jordanian Dinar',
        code: 'JOD',
        number: '400',
    },
    {
        country: 'KAZAKHSTAN',
        currency: 'Tenge',
        code: 'KZT',
        number: '398',
    },
    {
        country: 'KENYA',
        currency: 'Kenyan Shilling',
        code: 'KES',
        number: '404',
    },
    {
        country: 'KIRIBATI',
        currency: 'Australian Dollar',
        code: 'AUD',
        number: '036',
    },
    {
        country: 'KOREA (THE DEMOCRATIC PEOPLE\'S REPUBLIC OF)',
        currency: 'North Korean Won',
        code: 'KPW',
        number: '408',
    },
    {
        country: 'KOREA (THE REPUBLIC OF)',
        currency: 'Won',
        code: 'KRW',
        number: '410',
    },
    {
        country: 'KUWAIT',
        currency: 'Kuwaiti Dinar',
        code: 'KWD',
        number: '414',
    },
    {
        country: 'KYRGYZSTAN',
        currency: 'Som',
        code: 'KGS',
        number: '417',
    },
    {
        country: 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC (THE)',
        currency: 'Kip',
        code: 'LAK',
        number: '418',
    },
    {
        country: 'LATVIA',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'LEBANON',
        currency: 'Lebanese Pound',
        code: 'LBP',
        number: '422',
    },
    {
        country: 'LESOTHO',
        currency: 'Loti',
        code: 'LSL',
        number: '426',
    },
    {
        country: 'LESOTHO',
        currency: 'Rand',
        code: 'ZAR',
        number: '710',
    },
    {
        country: 'LIBERIA',
        currency: 'Liberian Dollar',
        code: 'LRD',
        number: '430',
    },
    {
        country: 'LIBYA',
        currency: 'Libyan Dinar',
        code: 'LYD',
        number: '434',
    },
    {
        country: 'LIECHTENSTEIN',
        currency: 'Swiss Franc',
        code: 'CHF',
        number: '756',
    },
    {
        country: 'LITHUANIA',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'LUXEMBOURG',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'MACAO',
        currency: 'Pataca',
        code: 'MOP',
        number: '446',
    },
    {
        country: 'MADAGASCAR',
        currency: 'Malagasy Ariary',
        code: 'MGA',
        number: '969',
    },
    {
        country: 'MALAWI',
        currency: 'Kwacha',
        code: 'MWK',
        number: '454',
    },
    {
        country: 'MALAYSIA',
        currency: 'Malaysian Ringgit',
        code: 'MYR',
        number: '458',
    },
    {
        country: 'MALDIVES',
        currency: 'Rufiyaa',
        code: 'MVR',
        number: '462',
    },
    {
        country: 'MALI',
        currency: 'CFA Franc BCEAO',
        code: 'XOF',
        number: '952',
    },
    {
        country: 'MALTA',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'MARSHALL ISLANDS (THE)',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'MARTINIQUE',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'MAURITANIA',
        currency: 'Ouguiya',
        code: 'MRU',
        number: '929',
    },
    {
        country: 'MAURITIUS',
        currency: 'Mauritius Rupee',
        code: 'MUR',
        number: '480',
    },
    {
        country: 'MAYOTTE',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP',
        currency: 'ADB Unit of Account',
        code: 'XUA',
        number: '965',
    },
    {
        country: 'MEXICO',
        currency: 'Mexican Peso',
        code: 'MXN',
        number: '484',
    },
    {
        country: 'MEXICO',
        currency: 'Mexican Unidad de Inversion (UDI)',
        code: 'MXV',
        number: '979',
    },
    {
        country: 'MICRONESIA (FEDERATED STATES OF)',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'MOLDOVA (THE REPUBLIC OF)',
        currency: 'Moldovan Leu',
        code: 'MDL',
        number: '498',
    },
    {
        country: 'MONACO',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'MONGOLIA',
        currency: 'Tugrik',
        code: 'MNT',
        number: '496',
    },
    {
        country: 'MONTENEGRO',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'MONTSERRAT',
        currency: 'East Caribbean Dollar',
        code: 'XCD',
        number: '951',
    },
    {
        country: 'MOROCCO',
        currency: 'Moroccan Dirham',
        code: 'MAD',
        number: '504',
    },
    {
        country: 'MOZAMBIQUE',
        currency: 'Mozambique Metical',
        code: 'MZN',
        number: '943',
    },
    {
        country: 'MYANMAR',
        currency: 'Kyat',
        code: 'MMK',
        number: '104',
    },
    {
        country: 'NAMIBIA',
        currency: 'Namibia Dollar',
        code: 'NAD',
        number: '516',
    },
    {
        country: 'NAMIBIA',
        currency: 'Rand',
        code: 'ZAR',
        number: '710',
    },
    {
        country: 'NAURU',
        currency: 'Australian Dollar',
        code: 'AUD',
        number: '036',
    },
    {
        country: 'NEPAL',
        currency: 'Nepalese Rupee',
        code: 'NPR',
        number: '524',
    },
    {
        country: 'NETHERLANDS (THE)',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'NEW CALEDONIA',
        currency: 'CFP Franc',
        code: 'XPF',
        number: '953',
    },
    {
        country: 'NEW ZEALAND',
        currency: 'New Zealand Dollar',
        code: 'NZD',
        number: '554',
    },
    {
        country: 'NICARAGUA',
        currency: 'Cordoba Oro',
        code: 'NIO',
        number: '558',
    },
    {
        country: 'NIGER (THE)',
        currency: 'CFA Franc BCEAO',
        code: 'XOF',
        number: '952',
    },
    {
        country: 'NIGERIA',
        currency: 'Naira',
        code: 'NGN',
        number: '566',
    },
    {
        country: 'NIUE',
        currency: 'New Zealand Dollar',
        code: 'NZD',
        number: '554',
    },
    {
        country: 'NORFOLK ISLAND',
        currency: 'Australian Dollar',
        code: 'AUD',
        number: '036',
    },
    {
        country: 'NORTHERN MARIANA ISLANDS (THE)',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'NORWAY',
        currency: 'Norwegian Krone',
        code: 'NOK',
        number: '578',
    },
    {
        country: 'OMAN',
        currency: 'Rial Omani',
        code: 'OMR',
        number: '512',
    },
    {
        country: 'PAKISTAN',
        currency: 'Pakistan Rupee',
        code: 'PKR',
        number: '586',
    },
    {
        country: 'PALAU',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'PALESTINE, STATE OF',
        currency: 'No universal currency',
        code: ' ',
        number: ','
    },
    {
        country: 'PANAMA',
        currency: 'Balboa',
        code: 'PAB',
        number: '590',
    },
    {
        country: 'PANAMA',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'PAPUA NEW GUINEA',
        currency: 'Kina',
        code: 'PGK',
        number: '598',
    },
    {
        country: 'PARAGUAY',
        currency: 'Guarani',
        code: 'PYG',
        number: '600',
    },
    {
        country: 'PERU',
        currency: 'Nuevo Sol',
        code: 'PEN',
        number: '604',
    },
    {
        country: 'PHILIPPINES (THE)',
        currency: 'Philippine Peso',
        code: 'PHP',
        number: '608',
    },
    {
        country: 'PITCAIRN',
        currency: 'New Zealand Dollar',
        code: 'NZD',
        number: '554',
    },
    {
        country: 'POLAND',
        currency: 'Zloty',
        code: 'PLN',
        number: '985',
    },
    {
        country: 'PORTUGAL',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'PUERTO RICO',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'QATAR',
        currency: 'Qatari Rial',
        code: 'QAR',
        number: '634',
    },
    {
        country: 'REPUBLIC OF NORTH MACEDONIA',
        currency: 'Denar',
        code: 'MKD',
        number: '807',
    },
    {
        country: 'ROMANIA',
        currency: 'Romanian Leu',
        code: 'RON',
        number: '946',
    },
    {
        country: 'RUSSIAN FEDERATION (THE)',
        currency: 'Russian Ruble',
        code: 'RUB',
        number: '643',
    },
    {
        country: 'RWANDA',
        currency: 'Rwanda Franc',
        code: 'RWF',
        number: '646',
    },
    {
        country: 'RÉUNION',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'SAINT BARTHÉLEMY',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
        currency: 'Saint Helena Pound',
        code: 'SHP',
        number: '654',
    },
    {
        country: 'SAINT KITTS AND NEVIS',
        currency: 'East Caribbean Dollar',
        code: 'XCD',
        number: '951',
    },
    {
        country: 'SAINT LUCIA',
        currency: 'East Caribbean Dollar',
        code: 'XCD',
        number: '951',
    },
    {
        country: 'SAINT MARTIN (FRENCH PART)',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'SAINT PIERRE AND MIQUELON',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'SAINT VINCENT AND THE GRENADINES',
        currency: 'East Caribbean Dollar',
        code: 'XCD',
        number: '951',
    },
    {
        country: 'SAMOA',
        currency: 'Tala',
        code: 'WST',
        number: '882',
    },
    {
        country: 'SAN MARINO',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'SAO TOME AND PRINCIPE',
        currency: 'Dobra',
        code: 'STN',
        number: '930',
    },
    {
        country: 'SAUDI ARABIA',
        currency: 'Saudi Riyal',
        code: 'SAR',
        number: '682',
    },
    {
        country: 'SENEGAL',
        currency: 'CFA Franc BCEAO',
        code: 'XOF',
        number: '952',
    },
    {
        country: 'SERBIA',
        currency: 'Serbian Dinar',
        code: 'RSD',
        number: '941',
    },
    {
        country: 'SEYCHELLES',
        currency: 'Seychelles Rupee',
        code: 'SCR',
        number: '690',
    },
    {
        country: 'SIERRA LEONE',
        currency: 'Leone',
        code: 'SLL',
        number: '694',
    },
    {
        country: 'SINGAPORE',
        currency: 'Singapore Dollar',
        code: 'SGD',
        number: '702',
    },
    {
        country: 'SINT MAARTEN (DUTCH PART)',
        currency: 'Netherlands Antillean Guilder',
        code: 'ANG',
        number: '532',
    },
    {
        country: 'SLOVAKIA',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'SLOVENIA',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'SOLOMON ISLANDS',
        currency: 'Solomon Islands Dollar',
        code: 'SBD',
        number: '090',
    },
    {
        country: 'SOMALIA',
        currency: 'Somali Shilling',
        code: 'SOS',
        number: '706',
    },
    {
        country: 'SOUTH AFRICA',
        currency: 'Rand',
        code: 'ZAR',
        number: '710',
    },
    {
        country: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
        currency: 'No universal currency',
        code: ' ',
        number: ','
    },
    {
        country: 'SOUTH SUDAN',
        currency: 'South Sudanese Pound',
        code: 'SSP',
        number: '728',
    },
    {
        country: 'SPAIN',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
    {
        country: 'SRI LANKA',
        currency: 'Sri Lanka Rupee',
        code: 'LKR',
        number: '144',
    },
    {
        country: 'SUDAN (THE)',
        currency: 'Sudanese Pound',
        code: 'SDG',
        number: '938',
    },
    {
        country: 'SURINAME',
        currency: 'Surinam Dollar',
        code: 'SRD',
        number: '968',
    },
    {
        country: 'SVALBARD AND JAN MAYEN',
        currency: 'Norwegian Krone',
        code: 'NOK',
        number: '578',
    },
    {
        country: 'SWAZILAND',
        currency: 'Lilangeni',
        code: 'SZL',
        number: '748',
    },
    {
        country: 'SWEDEN',
        currency: 'Swedish Krona',
        code: 'SEK',
        number: '752',
    },
    {
        country: 'SWITZERLAND',
        currency: 'Swiss Franc',
        code: 'CHF',
        number: '756',
    },
    {
        country: 'SWITZERLAND',
        currency: 'WIR Franc',
        code: 'CHW',
        number: '948',
    },
    {
        country: 'SYRIAN ARAB REPUBLIC',
        currency: 'Syrian Pound',
        code: 'SYP',
        number: '760',
    },
    {
        country: 'TAIWAN (PROVINCE OF CHINA)',
        currency: 'New Taiwan Dollar',
        code: 'TWD',
        number: '901',
    },
    {
        country: 'TAJIKISTAN',
        currency: 'Somoni',
        code: 'TJS',
        number: '972',
    },
    {
        country: 'TANZANIA, UNITED REPUBLIC OF',
        currency: 'Tanzanian Shilling',
        code: 'TZS',
        number: '834',
    },
    {
        country: 'THAILAND',
        currency: 'Baht',
        code: 'THB',
        number: '764',
    },
    {
        country: 'TIMOR-LESTE',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'TOGO',
        currency: 'CFA Franc BCEAO',
        code: 'XOF',
        number: '952',
    },
    {
        country: 'TOKELAU',
        currency: 'New Zealand Dollar',
        code: 'NZD',
        number: '554',
    },
    {
        country: 'TONGA',
        currency: 'Pa’anga',
        code: 'TOP',
        number: '776',
    },
    {
        country: 'TRINIDAD AND TOBAGO',
        currency: 'Trinidad and Tobago Dollar',
        code: 'TTD',
        number: '780',
    },
    {
        country: 'TUNISIA',
        currency: 'Tunisian Dinar',
        code: 'TND',
        number: '788',
    },
    {
        country: 'TURKEY',
        currency: 'Turkish Lira',
        code: 'TRY',
        number: '949',
    },
    {
        country: 'TURKMENISTAN',
        currency: 'Turkmenistan New Manat',
        code: 'TMT',
        number: '934',
    },
    {
        country: 'TURKS AND CAICOS ISLANDS (THE)',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'TUVALU',
        currency: 'Australian Dollar',
        code: 'AUD',
        number: '036',
    },
    {
        country: 'UGANDA',
        currency: 'Uganda Shilling',
        code: 'UGX',
        number: '800',
    },
    {
        country: 'UKRAINE',
        currency: 'Hryvnia',
        code: 'UAH',
        number: '980',
    },
    {
        country: 'UNITED ARAB EMIRATES (THE)',
        currency: 'UAE Dirham',
        code: 'AED',
        number: '784',
    },
    {
        country: 'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)',
        currency: 'Pound Sterling',
        code: 'GBP',
        number: '826',
    },
    {
        country: 'UNITED STATES MINOR OUTLYING ISLANDS (THE)',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'UNITED STATES OF AMERICA (THE)',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'UNITED STATES OF AMERICA (THE)',
        currency: 'US Dollar (Next day)',
        code: 'USN',
        number: '997',
    },
    {
        country: 'URUGUAY',
        currency: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
        code: 'UYI',
        number: '940',
    },
    {
        country: 'URUGUAY',
        currency: 'Peso Uruguayo',
        code: 'UYU',
        number: '858',
    },
    {
        country: 'UZBEKISTAN',
        currency: 'Uzbekistan Sum',
        code: 'UZS',
        number: '860',
    },
    {
        country: 'VANUATU',
        currency: 'Vatu',
        code: 'VUV',
        number: '548',
    },
    {
        country: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)',
        currency: 'Bolivar',
        code: 'VEF',
        number: '937',
    },
    {
        country: 'VIET NAM',
        currency: 'Dong',
        code: 'VND',
        number: '704',
    },
    {
        country: 'VIRGIN ISLANDS (BRITISH)',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'VIRGIN ISLANDS (U.S.)',
        currency: 'US Dollar',
        code: 'USD',
        number: '840',
    },
    {
        country: 'WALLIS AND FUTUNA',
        currency: 'CFP Franc',
        code: 'XPF',
        number: '953',
    },
    {
        country: 'WESTERN SAHARA',
        currency: 'Moroccan Dirham',
        code: 'MAD',
        number: '504',
    },
    {
        country: 'YEMEN',
        currency: 'Yemeni Rial',
        code: 'YER',
        number: '886',
    },
    {
        country: 'ZAMBIA',
        currency: 'Zambian Kwacha',
        code: 'ZMW',
        number: '967',
    },
    {
        country: 'ZIMBABWE',
        currency: 'Zimbabwe Dollar',
        code: 'ZWL',
        number: '932',
    },
    {
        country: 'ÅLAND ISLANDS',
        currency: 'Euro',
        code: 'EUR',
        number: '978',
    },
];


const alpha2Codes = {
    'AFGHANISTAN': 'AF',
    'ALBANIA': 'AL',
    'ALGERIA': 'DZ',
    'AMERICAN SAMOA': 'AS',
    'ANDORRA': 'AD',
    'ANGOLA': 'AO',
    'ANGUILLA': 'AI',
    'ANTARCTICA': 'AQ',
    'ANTIGUA AND BARBUDA': 'AG',
    'ARGENTINA': 'AR',
    'ARMENIA': 'AM',
    'ARUBA': 'AW',
    'AUSTRALIA': 'AU',
    'AUSTRIA': 'AT',
    'AZERBAIJAN': 'AZ',
    'BAHAMAS (THE)': 'BS',
    'BAHRAIN': 'BH',
    'BANGLADESH': 'BD',
    'BARBADOS': 'BB',
    'BELARUS': 'BY',
    'BELGIUM': 'BE',
    'BELIZE': 'BZ',
    'BENIN': 'BJ',
    'BERMUDA': 'BM',
    'BHUTAN': 'BT',
    'BOLIVIA (PLURINATIONAL STATE OF)': 'BO',
    'BONAIRE, SINT EUSTATIUS AND SABA': 'BQ',
    'BOSNIA AND HERZEGOVINA': 'BA',
    'BOTSWANA': 'BW',
    'BOUVET ISLAND': 'BV',
    'BRAZIL': 'BR',
    'BRITISH INDIAN OCEAN TERRITORY (THE)': 'IO',
    'BRUNEI DARUSSALAM': 'BN',
    'BULGARIA': 'BG',
    'BURKINA FASO': 'BF',
    'BURUNDI': 'BI',
    'CABO VERDE': 'CV',
    'CAMBODIA': 'KH',
    'CAMEROON': 'CM',
    'CANADA': 'CA',
    'CAYMAN ISLANDS (THE)': 'KY',
    'CENTRAL AFRICAN REPUBLIC (THE)': 'CF',
    'CHAD': 'TD',
    'CHILE': 'CL',
    'CHINA': 'CN',
    'CHRISTMAS ISLAND': 'CX',
    'COCOS (KEELING) ISLANDS (THE)': 'CC',
    'COLOMBIA': 'CO',
    'COMOROS (THE)': 'KM',
    'CONGO (THE DEMOCRATIC REPUBLIC OF THE)': 'CD',
    'CONGO (THE)': 'CG',
    'COOK ISLANDS (THE)': 'CK',
    'COSTA RICA': 'CR',
    'CROATIA': 'HR',
    'CUBA': 'CU',
    'CURAÇAO': 'CW',
    'CYPRUS': 'CY',
    'CZECH REPUBLIC (THE)': 'CZ',
    'CÔTE D\'IVOIRE': 'CI',
    'DENMARK': 'DK',
    'DJIBOUTI': 'DJ',
    'DOMINICA': 'DM',
    'DOMINICAN REPUBLIC (THE)': 'DO',
    'ECUADOR': 'EC',
    'EGYPT': 'EG',
    'EL SALVADOR': 'SV',
    'EQUATORIAL GUINEA': 'GQ',
    'ERITREA': 'ER',
    'ESTONIA': 'EE',
    'ETHIOPIA': 'ET',
    'EUROPEAN UNION': 'EU',
    'FALKLAND ISLANDS (THE) [MALVINAS]': 'FK',
    'FAROE ISLANDS (THE)': 'FO',
    'FIJI': 'FJ',
    'FINLAND': 'FI',
    'FRANCE': 'FR',
    'FRENCH GUIANA': 'GF',
    'FRENCH POLYNESIA': 'PF',
    'FRENCH SOUTHERN TERRITORIES (THE)': 'TF',
    'GABON': 'GA',
    'GAMBIA (THE)': 'GM',
    'GEORGIA': 'GE',
    'GERMANY': 'DE',
    'GHANA': 'GH',
    'GIBRALTAR': 'GI',
    'GREECE': 'GR',
    'GREENLAND': 'GL',
    'GRENADA': 'GD',
    'GUADELOUPE': 'GP',
    'GUAM': 'GU',
    'GUATEMALA': 'GT',
    'GUINEA': 'GN',
    'GUINEA-BISSAU': 'GW',
    'GUYANA': 'GY',
    'HAITI': 'HT',
    'HEARD ISLAND AND McDONALD ISLANDS': 'HM',
    'HOLY SEE (THE)': 'VA',
    'HONDURAS': 'HN',
    'HONG KONG': 'HK',
    'HUNGARY': 'HU',
    'ICELAND': 'IS',
    'INDIA': 'IN',
    'INDONESIA': 'ID',
    'INTERNATIONAL MONETARY FUND (IMF)': 'IM',
    'IRAN (ISLAMIC REPUBLIC OF)': 'IR',
    'IRAQ': 'IQ',
    'IRELAND': 'IE',
    'ISLE OF MAN': 'IM',
    'ISRAEL': 'IL',
    'ITALY': 'IT',
    'JAMAICA': 'JM',
    'JAPAN': 'JP',
    'JERSEY': 'JE',
    'JORDAN': 'JO',
    'KAZAKHSTAN': 'KZ',
    'KENYA': 'KE',
    'KIRIBATI': 'KI',
    'KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)': 'KP',
    'KOREA (THE REPUBLIC OF)': 'KR',
    'KUWAIT': 'KW',
    'KYRGYZSTAN': 'KG',
    'LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)': 'LA',
    'LATVIA': 'LV',
    'LEBANON': 'LB',
    'LESOTHO': 'LS',
    'LIBERIA': 'LR',
    'LIBYA': 'LY',
    'LIECHTENSTEIN': 'LI',
    'LITHUANIA': 'LT',
    'LUXEMBOURG': 'LU',
    'MACAO': 'MO',
    'MADAGASCAR': 'MG',
    'MALAWI': 'MW',
    'MALAYSIA': 'MY',
    'MALDIVES': 'MV',
    'MALI': 'ML',
    'MALTA': 'MT',
    'MARSHALL ISLANDS (THE)': 'MH',
    'MARTINIQUE': 'MQ',
    'MAURITANIA': 'MR',
    'MAURITIUS': 'MU',
    'MAYOTTE': 'YT',
    'MEXICO': 'MX',
    'MICRONESIA (FEDERATED STATES OF)': 'FM',
    'MOLDOVA (THE REPUBLIC OF)': 'MD',
    'MONACO': 'MC',
    'MONGOLIA': 'MN',
    'MONTENEGRO': 'ME',
    'MONTSERRAT': 'MS',
    'MOROCCO': 'MA',
    'MOZAMBIQUE': 'MZ',
    'MYANMAR': 'MM',
    'NAMIBIA': 'NA',
    'NAURU': 'NR',
    'NEPAL': 'NP',
    'NETHERLANDS (THE)': 'NL',
    'NEW CALEDONIA': 'NC',
    'NEW ZEALAND': 'NZ',
    'NICARAGUA': 'NI',
    'NIGER (THE)': 'NE',
    'NIGERIA': 'NG',
    'NIUE': 'NU',
    'NORFOLK ISLAND': 'NF',
    'NORTHERN MARIANA ISLANDS (THE)': 'MP',
    'NORWAY': 'NO',
    'OMAN': 'OM',
    'PAKISTAN': 'PK',
    'PALAU': 'PW',
    'PALESTINE, STATE OF': 'PS',
    'PANAMA': 'PA',
    'PAPUA NEW GUINEA': 'PG',
    'PARAGUAY': 'PY',
    'PERU': 'PE',
    'PHILIPPINES (THE)': 'PH',
    'PITCAIRN': 'PN',
    'POLAND': 'PL',
    'PORTUGAL': 'PT',
    'PUERTO RICO': 'PR',
    'QATAR': 'QA',
    'REPUBLIC OF NORTH MACEDONIA': 'MK',
    'ROMANIA': 'RO',
    'RUSSIAN FEDERATION (THE)': 'RU',
    'RWANDA': 'RW',
    'RÉUNION': 'RE',
    'SAINT BARTHÉLEMY': 'BL',
    'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA': 'SH',
    'SAINT KITTS AND NEVIS': 'KN',
    'SAINT LUCIA': 'LC',
    'SAINT MARTIN (FRENCH PART)': 'MF',
    'SAINT PIERRE AND MIQUELON': 'PM',
    'SAINT VINCENT AND THE GRENADINES': 'VC',
    'SAMOA': 'WS',
    'SAN MARINO': 'SM',
    'SAO TOME AND PRINCIPE': 'ST',
    'SAUDI ARABIA': 'SA',
    'SENEGAL': 'SN',
    'SERBIA': 'RS',
    'SEYCHELLES': 'SC',
    'SIERRA LEONE': 'SL',
    'SINGAPORE': 'SG',
    'SINT MAARTEN (DUTCH PART)': 'SX',
    'SLOVAKIA': 'SK',
    'SLOVENIA': 'SI',
    'SOLOMON ISLANDS': 'SB',
    'SOMALIA': 'SO',
    'SOUTH AFRICA': 'ZA',
    'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS': 'GS',
    'SOUTH SUDAN': 'SS',
    'SPAIN': 'ES',
    'SRI LANKA': 'LK',
    'SUDAN (THE)': 'SD',
    'SURINAME': 'SR',
    'SVALBARD AND JAN MAYEN': 'SJ',
    'SWAZILAND': 'SZ',
    'SWEDEN': 'SE',
    'SWITZERLAND': 'CH',
    'SYRIAN ARAB REPUBLIC': 'SY',
    'TAIWAN (PROVINCE OF CHINA)': 'TW',
    'TAJIKISTAN': 'TJ',
    'TANZANIA, UNITED REPUBLIC OF': 'TZ',
    'THAILAND': 'TH',
    'TIMOR-LESTE': 'TL',
    'TOGO': 'TG',
    'TOKELAU': 'TK',
    'TONGA': 'TO',
    'TRINIDAD AND TOBAGO': 'TT',
    'TUNISIA': 'TN',
    'TURKEY': 'TR',
    'TURKMENISTAN': 'TM',
    'TURKS AND CAICOS ISLANDS (THE)': 'TC',
    'TUVALU': 'TV',
    'UGANDA': 'UG',
    'UKRAINE': 'UA',
    'UNITED ARAB EMIRATES (THE)': 'AE',
    'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)': 'GB',
    'UNITED STATES MINOR OUTLYING ISLANDS (THE)': 'UM',
    'UNITED STATES OF AMERICA (THE)': 'US',
    'URUGUAY': 'UY',
    'UZBEKISTAN': 'UZ',
    'VANUATU': 'VU',
    'VENEZUELA (BOLIVARIAN REPUBLIC OF)': 'VE',
    'VIET NAM': 'VN',
    'VIRGIN ISLANDS (BRITISH)': 'VG',
    'VIRGIN ISLANDS (U.S.)': 'VI',
    'WALLIS AND FUTUNA': 'WF',
    'WESTERN SAHARA': 'EH',
    'YEMEN': 'YE',
    'ZAMBIA': 'ZM',
    'ZIMBABWE': 'ZW',
    'ÅLAND ISLANDS': 'AX'
};

const NO_FLAG = [
    '',
    'AQ',
    'BL',
    'JE',
    'CW',
    'IM',
    'MF',
    'SS',
    'SX',
]


const updatedCountries = countries.map(country => ({
    ...country,
    currencyCode: country.code,
    code: alpha2Codes[country.country] || '' // Assign alpha-2 code if available
})).filter(country => !NO_FLAG.includes(country.code));


export const uniqueCountries = sortBy(uniqBy(updatedCountries, 'code'), 'code');

export const countryNameToCode = updatedCountries.reduce((previous, current) => { previous.set(current.country, current.code); return previous; }, new Map());
