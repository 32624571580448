import { Model, Store } from '@code-yellow/spider';
import { computed, observable } from 'mobx';
import { DRIVER } from './enums/AssetType';
import { TachoEvent, TachoEventStore } from './TachoEvent';
import { DriverReferenceStore } from './DriverReference';
import { RouteStore, Route } from './Route';
import { User } from 'store/User';
import { DateTime } from 'luxon';

export class Driver extends Model {
    static backendResourceName = 'driver';
    static omitFields = ['routesCount'];

    @observable id = null;
    @observable type = DRIVER;
    @observable firstName = '';
    @observable lastName = '';
    @observable phoneNumber = '';
    @observable weekendBreak = '';

    //Annotations
    @observable routesCount = 0;

    @observable routes = this.relation(RouteStore);
    @observable upcomingRoute = this.relation(Route);
    @observable currentRoute = this.relation(Route);
    @observable currentTachoEvent = this.relation(TachoEvent);
    @observable user = this.relation(User);
    @observable tachoEvents = this.relation(TachoEventStore);
    @observable driverReferences = this.relation(DriverReferenceStore);

    @computed
    get label() {
        return `${this.firstName} ${this.lastName}`;
    }

    @computed
    get subLabel() {
        return `${this.phoneNumber}`;
    }

    @computed
    get weekendBreakFormatted() {
        if (this.weekendBreak) {
            return JSON.parse(this.weekendBreak);
        }
    }

    @computed
    get dataReference() {
        const now = DateTime.now();

        if (this.driverReferences) {
            const validReferences = this.driverReferences.filter(reference => {
                const validFrom = reference.validFrom ? DateTime.fromISO(reference.validFrom) : null;
                const validTo = reference.validTo ? DateTime.fromISO(reference.validTo) : null;
                return (validFrom === null || validFrom <= now) && (validTo === null || validTo >= now);
            });

            if (validReferences.length > 0) {
                return validReferences[validReferences.length - 1].dataReference; // Return the last valid reference's dataReference
            }
        }

        return null;
    }

    createUser({ email, password }) {
        return this.api.put(`${this.url}create_user/`, {
            email: email,
            password: password,
        });
    }

    changeDriverData({ email, password }) {
        return this.api.put(`${this.url}change_driver_data/`, {
            email: email,
            password: password,
        });
    }

}

export class DriverStore extends Store<Driver> {
    Model = Driver;
    static backendResourceName = 'driver';
}
