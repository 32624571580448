import { computed, observable } from 'mobx';
import { Model, Store, Casts } from '@code-yellow/spider';
import { DriverLocation } from './DriverLocation';
import { DriverActionStore } from './DriverAction';
import { User } from 'store/User';
import { VirtualizedStore } from 'react-logistics-planning/src/store/VirtualizedStore';

export class DriverActivity extends Model {
    static backendResourceName = 'driver_app_activity';

    @observable id = null;
    @observable oid = null;
    @observable status = null;
    @observable type = null;

    @observable location = this.relation(DriverLocation);
    @observable assignedUser = this.relation(User);
    @observable actions = this.relation(DriverActionStore);

    @observable orderedArrivalDatetimeFrom = null;
    @observable orderedArrivalDatetimeUntil = null;

    @observable asap = false;

    @computed get dispatcherStatus() {
        return DriverActivity.computeDispatcherStatus(this.status);
    }

    casts() {
        return {
            orderedArrivalDatetimeFrom: Casts.datetime,
            orderedArrivalDatetimeUntil: Casts.datetime,
        };
    }

    static computeDispatcherStatus(status: string | null): string | null {
        if (status) {
            switch (status) {
                case 'draft':
                    return 'new';
                case 'eta':
                    return 'driving';
                case 'arrived':
                case 'started':
                    return 'working';
                default:
                    return status;
            }
        }

        return status;
    }
}

export class DriverActivityStore extends Store {
    Model = DriverActivity;
    static backendResourceName = 'driver_app_activity';
}

export class VirtualizedDriverActivityStore extends VirtualizedStore {
    Model = DriverActivity;
    static backendResourceName = 'driver_app_activity';
}