import { Model, Store, Casts, showSaveNotification, showErrorNotification, t } from '@code-yellow/spider';
import { computed, observable } from 'mobx';
import { TRUCK } from './enums/AssetType';
import { TruckPosition } from './TruckPosition';
import { TransicsETA } from '../../../react-logistics-transics/src/store/TransicsETA';
import ExternalDataSource from './enums/ExternalDataSource';
import { Location } from './Location';
import { parseBackendErrorMessages } from 'helpers';
import { DateTime } from 'luxon';
import { Customer } from 'react-logistics-administration/src';

export class Truck extends Model {
    static backendResourceName = 'truck';
    static omitFields = ['unfinishedActivities'];

    @observable id = null;
    @observable type = TRUCK;
    @observable fleetNumber = '';
    @observable licensePlate = '';
    @observable remarks = '';
    @observable active = true;

    @observable brandType = '';
    @observable mileage = '';
    @observable nextApk = null;
    @observable nextTacho = null;
    @observable permit = '';
    @observable permitExpirationDate = null;
    @observable waPermit = '';
    @observable wabco = false;
    @observable fuelCapacity = null;
    @observable dataSource = '';
    @observable dataReference = '';
    @observable soldAt = null;
    @observable unfinishedActivities = null;
    @observable truckInventory = '';

    @observable truckingCompany = this.relation(Customer);
    @observable currentPosition = this.relation(TruckPosition);
    @observable currentEta = this.relation(TransicsETA);

    casts() {
        return {
            nextApk: Casts.date,
            nextTacho: Casts.date,
            permitExpirationDate: Casts.date,
            soldAt: Casts.date,
        }
    }

    @computed get editUrl() {
        return `/master-data/truck/${this.id}/edit`;
    }

    @computed
    get label() {
        return `${this.fleetNumber}`;
    }

    @computed
    get fullLabel() {
        return `(${this.licensePlate}) ${this.fleetNumber}`;
    }

    @computed
    get subLabel() {
        return `${this.licensePlate}`;
    }

    upcomingEvent(event) {
        const today = DateTime.local();
        return (event > today && event < today.plus({ months: 2 }))
    }

    setActive(value) {
        this.active = value;
        this.save().then(() => {
            showSaveNotification();
        }).catch((e) => {
            this.active = !value;
            const errorText = parseBackendErrorMessages(e.response.data.errors);
            showErrorNotification(errorText ?? e);
        });
    }

    @computed get isConnectedToTransics() {
        return this.dataSource === ExternalDataSource.TRANSICS &&
            this.dataReference != null && this.dataReference !== '';
    }

    @computed get currentLocation(): Location | null {
        let location: Location | null = null;
        const isMobileOffice = this.dataSource === ExternalDataSource.MOBILEOFFICE;

        if ((this.isConnectedToTransics || isMobileOffice) && this.currentPosition?.id != null) {
            location = new Location(this.currentPosition.toLocationClosest());
        }

        return location;
    }

    @computed get currentLocationString() {
        const location = this.currentLocation;

        if (location != null && location.city !== '') {
            return `${location.city}, ${location.country}`
        } else {
            return t('masterData:general.noExtraInformationAvailable');
        }
    }

    @computed get hasFuel() {
        return this.isConnectedToTransics && this.currentPosition?.id && this.currentPosition?.fuelLevel;
    }

    @computed get fuelString() {
        if (this.isConnectedToTransics && this.currentPosition?.id && this.currentPosition?.fuelLevel) {
            return this.currentPosition.fuelLevel + '%';
        } else {
            return '-';
        }
    }

    @computed get distanceRange() {
        if (this.isConnectedToTransics && this.currentPosition?.id && this.currentPosition?.fuelLevel) {
            return this.currentPosition.distanceRange;
        }
    }

    @computed get transicsDriver() {
        if (this.isConnectedToTransics && this.currentPosition?.id != null) {
            return this.currentPosition.driver
        } else {
            return null
        }
    }

    @computed get hasEta() {
        return true;
    }

    @computed get etaString() {
        if (this.isConnectedToTransics && this.currentEta?.id != null) {
            return this.currentEta.stringRestEtaSimple
        } else {
            return t('masterData:general.na');
        }
    }

    @computed get odometerString() {
        if (this.isConnectedToTransics && this.currentPosition?.id != null) {
            return this.currentPosition.odometer
        } else {
            return t('masterData:general.na');
        }
    }

    @computed get etaInformation() {
        if (this.isConnectedToTransics && this.currentEta?.id != null) {
            return this.currentEta.stringExtended
        } else {
            return t('masterData:general.na');
        }
    }

    @computed get canBeMessaged() {
        if (this.dataSource === ExternalDataSource.MOBILEOFFICE) {
            return true;
        }

        return this.isConnectedToTransics;
    }
}

export class TruckStore extends Store<Truck> {
    Model = Truck;
    static backendResourceName = 'truck';
}
