import { ReactNode, useEffect, useRef } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import ReactDOM from 'react-dom';

export default function MapLegend({ content }: {content: ReactNode}) {
    const map = useMap();
    const legendRef = useRef();

    useEffect(() => {
        // Create a custom legend control
        const legend = L.control({ position: 'bottomright' });

        legend.onAdd = function () {
            legendRef.current = L.DomUtil.create('div', 'legend');

            legendRef.current.style.backgroundColor = 'white';
            legendRef.current.style.padding = '8px';
            legendRef.current.style.font = '10px Arial';
            legendRef.current.style.borderRadius = '4px';
            legendRef.current.style.opacity = '80%';

            return legendRef.current;
        };

        legend.addTo(map);

        // Clean up when component unmounts
        return () => {
            legend.remove();
        };
    }, [map]);

    // Ensure the legend container is created before rendering JSX into it
    return legendRef.current ? ReactDOM.createPortal(content, legendRef.current) : null;
}
