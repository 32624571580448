// Translations
import { i18next } from '@code-yellow/spider';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';

i18next.addResourceBundle('nl', 'customerPortal', nl, true);
i18next.addResourceBundle('en', 'customerPortal', en, true);
i18next.fallbackNS = ['customerPortal']

// Components

// Screen

// Store

// AppHeader
export * from './container/AppHeader';

// Routes
export *  from './container/Router';
