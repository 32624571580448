export default {
    nav: {
        administration: {
            label: 'Administration',
            customer: 'Customers',
        },
    },
    customer: {
        overview: {
            title: 'Customer overview',
            addButton: 'Add Customer',
            bulkActions: {
                assignToCustomerGroup: 'Assign to customer group',
                assignToEntity: 'Assign to entity',
            },
            assignToCustomerGroupModal: {
                title: 'Assign to customer group',
                selectCustomerGroup: 'Select customer group',
                cancelButton: 'Cancel',
                saveButton: 'Assign',
            },
            assignToEntityModal: {
                title: 'Assign to entity',
                selectCustomerGroup: 'Select entity',
                cancelButton: 'Cancel',
                saveButton: 'Assign',
            },
        },
        create: {
            title: 'Add customer',
        },
        edit: {
            title: 'Edit customer {{name}}',
            basicInfoTitle: 'Basic info',
            updateDetailsTitle: 'Update details',
            customerPortalTitle: 'Customer portal',
        },
        field: {
            name: {
                label: 'Name',
            },
            additionalNameField: {
                label: 'Name Addition',
                info: 'This addition, will be shown on the invoice.'
            },
            internalNotes: {
                label: 'Internal Notes',
            },
            reference: {
                label: 'Reference',
            },
            emailAddress: {
                label: 'Email Address',
            },
            phone: {
                label: 'Phone number',
            },
            customerNumber: {
                label: 'Customer number',
                shortLabel: 'CusNr.'
            },
            emailFromName: {
                label: 'Customer Update "From" name'
            },
            emailFromAddress: {
                label: 'Customer Update "From" email address '
            },
            emailFromNameDocumentsInvoices: {
                label: 'Documents & Invoices "from" name',
            },
            emailFromAddressDocumentsInvoices: {
                label: 'Doc. & Invoices "from" e-mail address',
            },
            iban: {
                label: 'IBAN',
            },
            bic: {
                label: 'BIC',
            },
            chamberOfCommerce: {
                label: 'Chamber of Commerce',
            },
            paymentTermCreditor: {
                label: 'Payment term creditor (days)',
            },
            paymentTermDebtor: {
                label: 'Payment term debtor (days)',
            },
            amountDue: {
                label: 'Amount due',
            },
            creditLimitAmount: {
                label: 'Credit limit',
            },
            creditLimitUsed: {
                label: 'Current credit',
            },
            creditLimitExpiryDate: {
                label: 'Expiry date',
            },
            visitingAddress:
            {
                label: 'Street'
            },
            visitingNumber:
            {
                label: 'Number'
            },
            visitingZipCode: {
                label: 'Zip Code'
            },
            visitingCity: {
                label: 'City'
            },
            visitingCountry: {
                label: 'Country'
            },
            invoicingAddress:
            {
                label: 'Street'
            },
            invoicingNumber:
            {
                label: 'Number'
            },
            invoicingZipCode: {
                label: 'Zip Code'
            },
            invoicingCity: {
                label: 'City'
            },
            invoicingCountry: {
                label: 'Country'
            },
            vatNumber: {
                label: 'VAT number'
            },
            vatCode: {
                label: 'VAT code',
            },
            documents: {
                label: 'Upload documents',
            },
            emailAddressUpdate: {
                label: 'Email addresses'
            },
            invoiceLanguage: {
                label: 'Invoice language',
                shortLabel: 'Lang.',
                option: {
                    nl: 'Dutch',
                    en: 'English',
                    de: 'German',
                }
            },
            portalEnabled: {
                label: 'Portal enabled',
                options: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No',
                },
            },
            portalUser: {
                label: 'Portal user email'
            },
        },
    },
    customerLocation: {
        modal: {
            addHeader: 'Add address',
            editHeader: 'Edit address',
        },
        field: {
            id: { label: 'ID' },
            name: { label: 'Name' },
            isInternal: {
                label: 'Is Internal Location',
                info: 'Location is an internal location. It is not shown on invoices.',
            },
        }
    },
    entity: {
        field: {
            invoiceTemplate: {
                label: 'Default invoice template',
                option: {
                    nl: 'Dutch',
                    en: 'English',
                    de: 'German',
                }
            },
            newInvoiceNumberFormat: {
                label: 'New invoice number format',
            },
            twinfieldOffice: {
                label: 'Twinfield office',
            },
            twinfieldCode: {
                label: 'Twinfield code',
            },
        }
    },
};
