export default {
    nav: {
        administration: {
            label: 'Administratie',
            customer: 'Klanten',
        },
    },
    customer: {
        overview: {
            title: 'Klanten overzicht',
            addButton: 'Klant',
            bulkActions: {
                assignToCustomerGroup: 'Toewijzen aan klantgroep',
                assignToEntity: 'Toewijzen aan entiteit',
            },
            assignToCustomerGroupModal: {
                title: 'Toewijzen aan klantgroep',
                selectCustomerGroup: 'Selecteer klantgroep',
                cancelButton: 'Annuleren',
                saveButton: 'Toewijzen',
            },
            assignToEntityModal: {
                title: 'Toewijzen aan entiteit',
                selectCustomerGroup: 'Selecteer entiteit',
                cancelButton: 'Annuleren',
                saveButton: 'Toewijzen',
            },
        },
        create: {
            title: 'Klant',
        },
        edit: {
            title: 'Klant bewerken: {{name}}',
            basicInfoTitle: 'Informatie',
            updateDetailsTitle: 'Details bewerken',
            customerPortalTitle: 'Klant portaal',
        },
        field: {
            name: {
                label: 'Klantnaam',
            },
            additionalNameField: {
                label: 'Naam Toevoeging',
                info: 'Deze toevoeging staat op de invoice.'
            },
            internalNotes: {
                label: 'Interne Notities',
            },
            reference: {
                label: 'Referentie',
            },
            emailAddress: {
                label: 'E-mailadres',
            },
            phone: {
                label: 'Telefoonnummer',
            },
            customerNumber: {
                label: 'Klantnummer',
                shortLabel: 'CusNr.'
            },
            emailFromName: {
                label: 'Naam afzender'
            },
            emailFromAddress: {
                label: 'E-mailadres'
            },
            emailFromNameDocumentsInvoices: {
                label: 'Naam afzender',
            },
            emailFromAddressDocumentsInvoices: {
                label: 'E-mailadres',
            },
            iban: {
                label: 'IBAN',
            },
            bic: {
                label: 'BIC',
            },
            chamberOfCommerce: {
                label: 'KVK nummer',
            },
            paymentTermCreditor: {
                label: 'Betalingstermijn krediteur (dagen)',
            },
            paymentTermDebtor: {
                label: 'Betalingstermijn debiteur (dagen)',
            },
            amountDue: {
                label: 'Verschuldigd bedrag',
            },
            creditLimitAmount: {
                label: 'Kredietlimiet',
            },
            creditLimitUsed: {
                label: 'Huidig krediet',
            },
            creditLimitExpiryDate: {
                label: 'Vervaldatum',
            },
            visitingAddress:
            {
                label: 'Straat'
            },
            visitingNumber:
            {
                label: 'Nummer'
            },
            visitingZipCode: {
                label: 'Postcode'
            },
            visitingCity: {
                label: 'Stad'
            },
            visitingCountry: {
                label: 'Land'
            },
            invoicingAddress:
            {
                label: 'Straat'
            },
            invoicingNumber:
            {
                label: 'Nummer'
            },
            invoicingZipCode: {
                label: 'Postcode'
            },
            invoicingCity: {
                label: 'Stad'
            },
            invoicingCountry: {
                label: 'Land'
            },
            vatNumber: {
                label: 'VAT nummer'
            },
            vatCode: {
                label: 'VAT code',
            },
            documents: {
                label: 'Document uploaden',
            },
            emailAddressUpdate: {
                label: 'E-mailadres'
            },
            invoiceLanguage: {
                label: 'Factuurtaal',
                shortLabel: 'Taal',
                option: {
                    nl: 'Nederlands',
                    en: 'Engels',
                    de: 'Duits',
                }
            },
            portalEnabled: {
                label: 'Portaal aan',
                options: {
                    all: 'Alle',
                    yes: 'Ja',
                    no: 'Nee',
                },
            },
            portalUser: {
                label: 'Portaal gebruiker email'
            },
        },
    },
    customerLocation: {
        modal: {
            addHeader: 'Add address',
            editHeader: 'Edit address',
        },
        field: {
            id: { label: 'ID' },
            name: { label: 'Name' },
            isInternal: {
                label: 'Is Interne Locatie',
                info: 'Locatie is een interne locatie, en wordt niet weergegeven op een factuur.',
            },
        },
    },
    entity: {
        field: {
            invoiceTemplate: {
                label: 'Factuursjabloon',
                option: {
                    nl: 'Nederlands',
                    en: 'Engels',
                    de: 'Duits',
                },
            },
            newInvoiceNumberFormat: {
                label: 'Nieuw factuur nummer formaat',
            },
            twinfieldOffice: {
                label: 'Twinfield office',
            },
            twinfieldCode: {
                label: 'Twinfield code',
            },
        }
    },
};
