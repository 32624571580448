export default {
    nav: {
        main: {
            assets: 'Master data',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog',
            users: 'Users',
            accessLog: 'Access Log',
            globalValues: 'Settings',
            globalFiles: 'Special files',
        }
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },
    user: {
        field: {
            groups: {
                value: {
                    driver: 'Driver',
                    admin: 'Admin',
                    dispatcher: 'Dispatcher',
                    finance: 'Finance',
                    customer_portal: 'Customer portal',
                }
            }
        }
    },
    globalValue: {
        edit: {
            title: 'Edit value of global setting',
        },
        overview: {
            title: 'Global settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
        info: {
            default_language: 'System language',
            payment_term_creditor: 'Default creditor payment term, automatically filled during creation of new customers.',
            payment_term_debtor: 'Default debtor payment term, automatically filled during creation of new customers.',
            average_fuel_consumption: 'Average fuel consumption, used to estimate the distance a truck can still drive given its current fuel level.',
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'Name for file',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Original user',
            },
            masqueradingAsUser: {
                label: 'Masquerading as user',
            },
            ipAddress: {
                label: 'IP address',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: 'We’re very sorry, but the application has crashed.',
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{week}}',
            value: 'W{{week}} - {{year}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
        period:{
            am: 'AM',
            pm: 'PM'
        }
    },
    pageNotFoundMessage: 'Page not found',
    pageNotAllowedMessage: 'You are not allowed to see this page',
    common: {
        pagination: {
            limit: 'Limit'
        },
        overviewRender: {
            mode: 'Mode',
            type: {
                relaxed: 'Relaxed',
                compact: 'Compact'
            }
        }
    },
    history: {
        title: {
            label: 'History',
        },
        emptyBox: {
            label: 'Empty box',
        },
        at: 'at',
        for: 'for',
        added: 'added',
        changed: 'changed',
        removed: 'removed',
    },
    modal: {
        copyToClipboard: 'Copy to clipboard',
        copiedSuccessfully: 'Copied successfully',
    },
    color: {
        yellow: 'Yellow',
        orange: 'Orange',
        darkorange: 'Dark orange',
        lightred: 'Light red',
        red: 'Red',
        darkred: 'Dark red',
        lightpink: 'Light pink',
        pink: 'Pink',
        darkpink: 'Dark pink',
        lightviolet: 'Light violet',
        violet: 'Violet',
        darkviolet: 'Dark violet',
        lightpurple: 'Light purple',
        purple: 'Purple',
        darkpurple: 'Dark purple',
        lightindigo: 'Light indigo',
        indigo: 'Indigo',
        darkindigo: 'Dark indigo',
        lightblue: 'Light blue',
        blue: 'Blue',
        darkblue: 'Dark blue',
        lightteal: 'Light teal',
        teal: 'Teal',
        darkteal: 'Dark teal',
        lightgreen: 'Light green',
        green: 'Green',
        darkgreen: 'Dark green',
        lightlime: 'Light lime',
        lime: 'Lime',
        darklime: 'Dark lime',
        lightbrown: 'Light brown',
        brown: 'Brown',
        darkbrown: 'Dark brown',
        lightgrey: 'Light grey',
        grey: 'Grey',
        black: 'Black',
        none: 'No color',
    },
    serialNumberFormat: {
        part: {
            text: {
                label: 'Text',
                content: 'Content',
            },
            date: {
                label: 'Date',
                part: {
                    label: 'Part',
                    year: { label: 'Year' },
                    month: {
                        label: 'Month',
                        text: 'Text',
                        names: {
                            january: {
                                label: 'January',
                                value: 'JAN',
                            },
                            february: {
                                label: 'February',
                                value: 'FEB',
                            },
                            march: {
                                label: 'March',
                                value: 'MAR',
                            },
                            april: {
                                label: 'April',
                                value: 'APR',
                            },
                            may: {
                                label: 'May',
                                value: 'MAY',
                            },
                            june: {
                                label: 'June',
                                value: 'JUN',
                            },
                            july: {
                                label: 'July',
                                value: 'JUL',
                            },
                            august: {
                                label: 'August',
                                value: 'AUG',
                            },
                            september: {
                                label: 'September',
                                value: 'SEP',
                            },
                            october: {
                                label: 'Oktober',
                                value: 'OKT',
                            },
                            november: {
                                label: 'November',
                                value: 'NOV',
                            },
                            december: {
                                label: 'December',
                                value: 'DEC',
                            },
                        },
                    },
                    day: { label: 'Day' },
                    isoyear: { label: 'ISO Year' },
                    isoweek: { label: 'ISO Week' },
                    isoweekday: {
                        label: 'ISO Weekday',
                        text: 'Text',
                        names: {
                            monday: {
                                label: 'Monday',
                                value: 'Mo',
                            },
                            tuesday: {
                                label: 'Tuesday',
                                value: 'Tu',
                            },
                            wednesday: {
                                label: 'Wednesday',
                                value: 'We',
                            },
                            thursday: {
                                label: 'Thursday',
                                value: 'Th',
                            },
                            friday: {
                                label: 'Friday',
                                value: 'Fr',
                            },
                            saturday: {
                                label: 'Saturday',
                                value: 'Sa',
                            },
                            sunday: {
                                label: 'Sunday',
                                value: 'Su',
                            },
                        },
                    },
                },
                format: 'Format',
            },
            code: {
                label: 'Code',
                alphabet: 'Alphabet',
                digits: 'Number',
                expand: 'Expands',
                defaultAlphabets: {
                    decimal: 'Dec',
                    binary: 'Bin',
                    hexadecimalLower: 'Hex (a)',
                    hexadecimalUpper: 'Hex (A)',
                    octal: 'Oct',
                    alphanumericLower: 'Alfanum (a)',
                    alphanumericUpper: 'Alfanum (A)',
                    alphabeticLower: 'Alfa (a)',
                    alphabeticUpper: 'Alfa (A)',
                    custom: 'Other, like:',
                },
            },
            anything: {
                label: 'Anything',
            },
            article_type: {
                label: 'Article Type Property',
                prop: {
                    label: 'Property',
                    code: 'Code',
                    barcode: 'Barcode',
                    metafield: 'Metafield',
                },
            },
        },
    },
};
