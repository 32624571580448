import { observable, computed } from 'mobx';
import { Model, Store } from '@code-yellow/spider';
import { Activity } from '..';

export class ActivityAction extends Model {
    static backendResourceName = 'activity_action';

    @observable id = null;
    @observable oid = null;
    @observable type = '';
    @observable label = '';
    @observable description = '';
    @observable value = '';
    @observable checked = false;
    @observable file = null;
    @observable file_type = null;
    @observable activity = this.relation(Activity);

    @computed get downloadUrl() {
        return `${this.file}&download=true`;
    }
}

export class ActivityActionStore extends Store {
    Model = ActivityAction;
    static backendResourceName = 'activity_action';
}