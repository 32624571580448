// Hacky helper to ease working with notifications.
import { SpecialViewStore } from '../index';
import { User } from '../store/User';

let viewStore: SpecialViewStore | null = null;

export function configureCurrentUser(givenViewStore: SpecialViewStore) {
    viewStore = givenViewStore;
}

export function getCurrentUser(): User | null {
    return viewStore?.currentUser;
}

export function isDriverUser(): boolean {
    return viewStore?.currentUser.isDriver;
}

export function inGroup(...args) {
    if (viewStore?.currentUser.inGroup) {
        return viewStore.currentUser.inGroup(...args);
    }

    // Fallback for projects who don't have hasPermission.
    return false;
}