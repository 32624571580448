import React from 'react';
import { observer } from 'mobx-react';
import { Customer, CustomerEdit } from 'react-core-administration/src';
import { afterSave } from '@code-yellow/spider';
import AdminEditScreen from 'component/AdminEditScreen'

@observer
export default class CustomerEditScreen extends AdminEditScreen {
    static baseUrl = '/administration/customer/';
    Model = Customer;
    relations = [
        'entity',
        'group',
        'defaultLedger',
        'portalUser',
    ];

    renderEdit() {
        const { viewStore, history } = this.props;

        return (
            <CustomerEdit
                customer={this.model}
                afterSave={afterSave(history, viewStore, this.model)}
            />
        );
    }
}
