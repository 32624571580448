import { observable } from 'mobx';
import { Model, Store } from '@code-yellow/spider';

export class GlobalValue extends Model {
    static backendResourceName = 'global_value';

    @observable id = null;
    @observable key = '';
    @observable value = '';  // NOTE: May need special casts for keys with custom (de)serializers...
    @observable readable = '';
    @observable writable = '';

    getUrl() {
        return `/account/global-value/${this.id}/edit`;
    }
}

export class GlobalValueStore extends Store {
    Model = GlobalValue;
    static backendResourceName = 'global_value';
}
