import React from 'react';
import { NavMenu, NavItem } from 're-cy-cle';
import { t } from '@code-yellow/spider';

export const renderCustomerPortalMenu = () => {
    return (
        <NavItem
            title={t('customerPortal:nav.customerPortal.label')}
            to="/customer-portal/trucks"
            activePath="/customer-portal/trucks"
        />
    );
};

export const renderCustomerPortalSubMenu = () => {
    return (
        <NavMenu data-test-menu-administration>
            <NavItem
                title={t('customerPortal:nav.customerPortal.trucks')}
                to="/customer-portal/trucks"
                activePath="/customer-portal/trucks"
            />
        </NavMenu>
    );
};

