// Translations
import { i18next } from '@code-yellow/spider';
import en from './i18n/translation/en';
import nl from './i18n/translation/nl';

// Components
export * from './component/LocationEditForm'

// Containers
export * from './container/Driver/Edit';
export * from './container/GoodsDescription/Edit';
export * from './container/Trailer/Edit';
export * from './container/Truck/DriverEdit';
export * from './container/Truck/Edit';

// Screen
export * from './screen/District/Overview';
export * from './screen/Driver/Edit';
export * from './screen/Driver/Overview';
export * from './screen/GoodsDescription/Edit';
export * from './screen/GoodsDescription/Overview';
export * from './screen/Trailer/Edit';
export * from './screen/Trailer/Overview';
export * from './screen/Truck/Edit';
export * from './screen/Truck/Overview';

// Store
export * from './store/Asset';
export * from './store/District';
export * from './store/Driver';
export * from './store/GoodsDescription';
export * from './store/Trailer';
export * from './store/Location';
export * from './store/Truck';
export * from './store/TruckPosition';
export * from './store/enums/AssetType';

// AppHeader
export * from './container/AppHeader';

// Routes
export *  from './container/Router';

// Settings
export * as MasterDataSettings from './settings';

i18next.addResourceBundle('nl', 'masterData', nl, true);
i18next.addResourceBundle('en', 'masterData', en, true);
i18next.fallbackNS = ['masterData']

export default function noDefaultExport() {
    throw Error('Default export is not defined')
 }