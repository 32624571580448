import React, { FunctionComponent } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, Tooltip } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import { Loader } from 'semantic-ui-react';
import { DefaultIcon, IconConfig } from './MarkerIcons';
import MapLegend from './MapLegend';


L.Marker.prototype.options.icon = DefaultIcon;
const AG_POSITION = [51.3423996, 6.1982054]

function getLatLng(position) {
    // Show AG HQ if there is nothing interesting to see
    if (!position || (position && !position.lat)) {
        return AG_POSITION;
    }

    if (typeof position.lat === 'number') {
        return [position.lat, position.lng];
    }

    return [parseFloat(position.lat), parseFloat(position.lng)];
}

type MapComponentProps = {
    markers: {
        position: [number, number];
        icon?: IconConfig;
        hint?: string;
    }[],
    activityRoute: [number, number][],
    routes: { positions, color }[],
    showMap: boolean,
    legend?: JSX.Element,
}

const MapComponent: FunctionComponent<MapComponentProps> = (props) => {
    const activityRoutePositions: Array<[number, number]> | null = (props.activityRoute !== undefined && props.activityRoute.length && props.activityRoute[0] !== undefined && props.activityRoute[0].map(getLatLng)) || [];
    const activityRoutePositionsCurrent: Array<[number, number]> | null = (props.activityRoute !== undefined && props.activityRoute.length && props.activityRoute[1] !== undefined && props.activityRoute[1].map(getLatLng)) || [];
    const COLOR_TO_PLANNED = 'var(--blue-500)';
    const COLOR_ACTIVE_TO_PLANNED = 'green';
    const bounds: [number, number][] = [].concat(props.markers?.map((marker) => marker.position));
    let markers = props.markers;
    if (!markers) {
        // Show AG HQ if there is nothing interesting to see
        markers = [{ position: AG_POSITION }];
    }

    if (!props.showMap && props.showMap !== undefined) {
        return (
            <Loader data-test-map-loader active style={{ position: 'relative' }} />
        )
    }

    return (
        // [TODO] height should be 100%
        <MapContainer
            center={markers?.length === 1 ? markers[0].position || AG_POSITION : undefined}
            zoom={10}
            style={{ height: '430px' }}
            bounds={bounds.length > 1 ? bounds : undefined}
            boundsOptions={{ padding: [20, 20] }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {!!activityRoutePositions && (
                <Polyline
                    positions={activityRoutePositions}
                    weight={4}
                    color={COLOR_TO_PLANNED}
                >
                </Polyline>
            )}
            {!!activityRoutePositionsCurrent && (
                <Polyline
                    positions={activityRoutePositionsCurrent}
                    weight={4}
                    color={COLOR_ACTIVE_TO_PLANNED}
                >
                </Polyline>
            )}
            {props.routes?.map(route => (
                <Polyline
                    positions={route.positions || []}
                    weight={4}
                    color={route.color}
                />
            ))
            }
            {markers?.map((marker, index) => (
                <Marker
                    key={index}
                    position={marker.position || AG_POSITION}
                    zIndexOffset={20}
                    icon={marker?.icon ?? DefaultIcon}
                >
                    {marker?.hint && <Tooltip><p style={{ whiteSpace: 'pre-line', textWrap: 'nowrap' }}>{marker?.hint}</p></Tooltip>}
                </Marker>
            ))}
            {props.legend &&
                <MapLegend content={props.legend} />
            }
        </MapContainer>
    )
}

export default MapComponent;
