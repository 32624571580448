import React from 'react';
import { CancelButton, AdminEditModal, t, SaveButton, showSaveNotification, TargetTextInput, TargetDatePicker } from '@code-yellow/spider';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Driver } from '../../store/Driver';
import { Confirm, Image, Button, Icon, Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from 'semantic-ui-react';
import { observable } from 'mobx';
import { User } from 'store/User';
import { DriverActivity } from 'react-logistics-masterdata/src/store/DriverActivity';
import styled from 'styled-components';
import { DriverActionDocument } from 'react-logistics-masterdata/src/store/DriverActionDocument';
import { HistoryStore } from 'store/History';
import HistoryFeed from 'component/History/HistoryFeed';
import { UserAuthentication, DriverActivitiesPreview } from 'react-logistics-driverapp/src/index';

const ImageCustom = styled(Image)`
    margin-top: 20px!important;
    margin-bottom: 20px!important;
`;

const historyConfig = [{
    trackedField: 'status',
    showAsTitle: true,
    translationKey: 'driverApp',
    idOnly: true,
}];

const WrapTable = styled(Table)`
    margin-bottom: 0px!important;
    .disabled {
        opacity: 0.5;
    }
`;

@observer
export class DriverEditModal extends AdminEditModal {
    static propTypes = {
        model: PropTypes.instanceOf(Driver).isRequired,
        ...AdminEditModal.propTypes,
    };

    Model = Driver;
    relations = ['driverReferences'];

    @observable user = new User({ id: null });

    @observable selectedDocument: DriverActionDocument | null = null;
    @observable selectedActivity: DriverActivity | null = null;
    @observable history: HistoryStore | null = null;

    fields = [
        {
            type: 'text',
            name: 'firstName',
            label: t('masterData:driver.field.firstName.label'),
            placeholder: t('masterData:driver.field.firstName.placeholder'),
        },
        {
            type: 'text',
            name: 'lastName',
            label: t('masterData:driver.field.lastName.label'),
            placeholder: t('masterData:driver.field.lastName.placeholder'),
        },
        {
            type: 'text',
            name: 'phoneNumber',
            label: t('masterData:driver.field.phoneNumber.label'),
            placeholder: t('masterData:driver.field.phoneNumber.placeholder'),
        },
    ]

    afterInit() {
        const { model } = this.props;

        if (model?.user?.id) {
            this.user = model.user;
        } else {
            this.user = new User({ id: null });
        }
    }

    saveUser = async () => {
        if (!this.model) {
            return;
        }

        const origin = window.location.origin;

        const res = this.user?.id ?
            await this.model.changeDriverData({ email: this.user.email, password: this.user.password }) :
            await this.model.createUser({ email: this.user.email, password: this.user.password });

        const authUrl = origin.startsWith('https') ?
            `${origin}/activate?token=${res.token}` :
            `https://ag.incenova.com/activate?url=${origin}&token=${res.token}`;

        return authUrl;
    }

    fetchHistory = (activity) => {
        this.selectedActivity = activity;
        const store = new HistoryStore({ relations: ['user', 'changes'] });
        store.url = activity.url + 'history/';
        store.fetch().then(() => {
            this.history = store;
        })
    }

    renderHeader() {
        return this.model?.id
            ? t('masterData:driver.edit.title', { id: this.model.id })
            : t('masterData:driver.create.title')
    }

    save = ({ createNew = false, shouldClose = false }) => {
        const { afterSave } = this.props;

        return this.model
            .save({
                onlyChanges: true,
                relations: ['driverReferences'],
            })
            .then(() => {
                if (createNew) {
                    this.user = new User({ id: null });
                    this.model = this.props.store.add({});
                    //The fields needs to be cleared manually
                    this.model.name = '';
                    this.model.__activeCurrentRelations = ['driverReferences'];
                    this.model.__activeRelations = ['driverReferences'];
                } else if (shouldClose) {
                    afterSave();
                    this.onClose();
                }
                showSaveNotification()
            })
    };

    onClose() {
        const { afterSave } = this.props;

        afterSave();
        super.onClose();
    }

    renderRightButtons() {
        return <>
                <SaveButton
                    primary
                    compact
                    loading={this.model.isLoading}
                    onClick={this.save}
                />
                {this.model?.id && <SaveButton primary compact
                    onClick={() => this.save({ createNew: true })}
                    content={t('masterData:form.saveCreateEmpty')}
                    loading={this.model.isLoading}
                />}
                <SaveButton primary compact
                    data-test-save-same-driver-button
                    onClick={() => this.save({ shouldClose: true })}
                    content={t('masterData:form.saveClose')}
                    loading={this.model.isLoading}
                />
        </>
    }

    renderLeftButtons() {
        return (
            <CancelButton onClick={this.onClose} />
        );
    }

    renderContent(fields) {
        return (
            <>
                {super.renderContent(fields)}
                {this._renderDriverReference()}
                {this._renderUserContent()}
                {this._renderActivities()}
            </>
        );
    }

    _renderUserContent = () => {
        return (
            <UserAuthentication user={this.user} disabled={this.model.isNew} saveUser={this.saveUser} />
        );
    }

    _renderActivities = () => {
        return (
            <>
                {this.selectedActivity && <Confirm
                    open={true}
                    cancelButton={null}
                    onConfirm={() => this.selectedActivity = null}
                    content={
                        <HistoryFeed object={this.selectedActivity} config={historyConfig} history={this.history} />
                    } />}
                {this.selectedDocument && <Confirm
                    open={true}
                    cancelButton={t('administration:document.overview.downloadButton')}
                    onConfirm={() => this.selectedDocument = null}
                    onCancel={() => {
                        window.open(this.selectedDocument?.downloadUrl, '_blank');
                    }}
                    content={
                        <ImageCustom centered
                            onClick={() => this.selectedDocument = null}
                            src={this.selectedDocument?.file}
                            height='400px'
                            width='400px'
                        />
                    } />}
                <DriverActivitiesPreview user={this.user} documentSelected={(document) => this.selectedDocument = document} activitySelected={this.fetchHistory} />
            </>
        );
    }

    onAddDriverReference = () => {
        this.model?.driverReferences.add({})
    }
    onDeleteDriverReference = (driverReference) => {
        this.model?.driverReferences.remove(driverReference)
    }

    driverReferenceRow = ({ driverReference, index, onDeleteDriverReference }) => (
        <TableRow disabled={index !== 0} data-test-table-row-driver-key>
            <TableCell>
                <TargetTextInput noLabel
                    target={driverReference}
                    name="dataReference"
                    placeholder={t('masterData:driver.field.dataReference.placeholder')}
                    disabled={index !== 0}
                    size='small'
                />
            </TableCell>
            <TableCell>
                <TargetDatePicker noLabel
                    width={8}
                    target={driverReference}
                    name="validFrom"
                    disabled={index !== 0}
                    size='small'
                />
            </TableCell>
            <TableCell>
                <TargetDatePicker noLabel
                    width={8}
                    target={driverReference}
                    name="validTo"
                    disabled={index !== 0}
                    size='small'
                />
            </TableCell>
            <TableCell>
                <Button primary compact icon
                    size='mini'
                    onClick={() => onDeleteDriverReference(driverReference)}
                    disabled={index !== 0}
                    data-test-delete-driver-data-reference
                >
                    <Icon name="remove" />
                </Button>
            </TableCell>
        </TableRow>
    );

    _renderDriverReference = () => {
        return (
            <>
                {this.model?.driverReferences?.models?.length > 0 &&
                    <WrapTable celled compact data-test-table-driver-reference-key>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>{t('masterData:driver.field.dataReference.label')}</TableHeaderCell>
                                <TableHeaderCell>{t('masterData:driver.field.validFrom.label')}</TableHeaderCell>
                                <TableHeaderCell>{t('masterData:driver.field.validTo.label')}</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {this.model?.driverReferences
                            .filter(driverReference => driverReference.id === null)
                            .map((driverReference, index) =>
                                this.driverReferenceRow({
                                    driverReference,
                                    index,
                                    onDeleteDriverReference: this.onDeleteDriverReference
                                })
                            )}

                            {this.model?.driverReferences
                            .filter(driverReference => driverReference.id)
                            .map((driverReference, index) =>
                                this.driverReferenceRow({
                                    driverReference,
                                    index,
                                    onDeleteDriverReference: this.onDeleteDriverReference
                                })
                            )}
                        </TableBody>
                    </WrapTable>
                }

                <Button primary icon
                    size='mini'
                    labelPosition='left'
                    onClick={() => this.onAddDriverReference()}
                    data-test-add-driver-data-reference
                    style={{ marginTop: '10px' }}
                >
                    <Icon name="add" />
                    {t('masterData:driver.field.addDriverReference.label')}
                </Button>
            </>
        );
    }
}
