import { Casts, Model, Store } from '@code-yellow/spider';
import { observable } from 'mobx';
import { DateTime } from 'luxon';

export class DriverActivitySyncStatus extends Model {
    static backendResourceName = 'driver_activity_sync_status';

    @observable id = null;

    @observable writtenAt: DateTime | null = null;
    @observable receivedAt: DateTime | null = null;
    @observable seenByReceiverAt: DateTime | null = null;

    @observable driverStatus = null;

    casts() {
        return {
            writtenAt: Casts.datetime,
            receivedAt: Casts.datetime,
            seenByReceiverAt: Casts.datetime,
        };
    }
}

export class DriverActivitySyncStatusStore extends Store {
    Model = DriverActivitySyncStatus;
    static backendResourceName = 'driver_activity_sync_status';
}
