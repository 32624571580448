export default {
    nav: {
        communication: {
            label: 'Communication',
            messagelog: 'Message Log',
            emailTemplate: 'Email templates',
        },
    },
    communication: {
        messagelog: {
            title: 'Message Log',
        }
    },
    message: {
        field: {
            type: {
                label: 'Type',
                value: {
                    email: 'Email',
                    sms: 'SMS',
                    telegram: 'Telegram',
                },
            },
            status: {
                label: 'Status',
                value: {
                    initialization_pending: 'Initialization pending',
                    initialization_failed: 'Initialization failed',
                    delivery_pending: 'Delivery pending',
                    delivery_success: 'Delivery success',
                    delivery_failed: 'Delivery failed',
                },
            },
            receivers: {
                label: 'Receivers',
            },
            dateSent: {
                label: 'Date sent',
            },
            text: {
                label: 'Text',
            },
        },
        overview: {
            filter: {
                view: {
                    label: 'Display type',
                    value: {
                        individual: 'Individual',
                        combined: 'Combined',
                    },
                },
                clearButton: {
                    label: 'Clear filters',
                }
            }
        },
    },
    chatmessage: {
        field: {
            text: {
                label: 'Text',
                placeholder: 'Type your message here...',
            },
            writtenAt: {
                label: 'Written at',
            },
            receivedAt: {
                label: 'Received at',
            },
            seenAt: {
                label: 'Seen at',
            },
        },
        maxSizeExceeded: 'File: {{name}} is too big. Maximum allowed size: {{size}} MB',
        invalidFileFormat: 'File: {{name}} has invalid format. Allowed: image/jpeg, image/png, application/pdf',
    },
    chat: {
        driverChat: {
            label: 'Driver chat'
        },
        sendMessageButton: 'Send fleet message',
        sendBulkMessageModal: {
            title: 'Send fleet message to trucks',
        }
    },
    emailTemplate: {
        overview: {
            title: 'Email template overview',
            addButton: 'Add new e-mail template',
        },
        edit: {
            title: 'Edit email template',
        },
        field: {
            id: {
                label: 'Id',
            },
            name: {
                label: 'Name',
            },
            tag: {
                label: 'Tag',
            },
            subject: {
                label: 'Subject',
            },
            senderEmail: {
                label: 'Sender',
            },
            userMessage: {
                label: 'User_message',
            },
            manageAttachments: {
                label: 'Option to add order documents to the email',
                options: {
                    enabled: 'Enabled',
                    disabled: 'Disabled',
                }
            }
        }
    },
    modal: {
        send: 'Send',
        sendEmail: {
            header: 'Send email',
            recipients: {
                label: 'Recipients',
            },
            subject: {
                label: 'Subject',
            },
            content: {
                label: 'Message',
            },
            template: {
                label: 'Template',
            },
            attachments: {
                label: 'Attachments',
            }
        },
    }
}
