import React from 'react';
import { showErrorNotification, TargetTextInput } from '@code-yellow/spider';
import { Label, Input, Flag, FlagNameValues, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';


type Country = {
    key: FlagNameValues,
    value: string,
    flag: FlagNameValues,
    content: string,
};

const countryOptions: Country[] = [
    { key: 'at', value: '+43', flag: 'at', content: 'Austria (+43)' },
    { key: 'be', value: '+32', flag: 'be', content: 'Belgium (+32)' },
    { key: 'bg', value: '+359', flag: 'bg', content: 'Bulgaria (+359)' },
    { key: 'hr', value: '+385', flag: 'hr', content: 'Croatia (+385)' },
    { key: 'cy', value: '+357', flag: 'cy', content: 'Cyprus (+357)' },
    { key: 'cz', value: '+420', flag: 'cz', content: 'Czech Republic (+420)' },
    { key: 'dk', value: '+45', flag: 'dk', content: 'Denmark (+45)' },
    { key: 'ee', value: '+372', flag: 'ee', content: 'Estonia (+372)' },
    { key: 'fi', value: '+358', flag: 'fi', content: 'Finland (+358)' },
    { key: 'fr', value: '+33', flag: 'fr', content: 'France (+33)' },
    { key: 'de', value: '+49', flag: 'de', content: 'Germany (+49)' },
    { key: 'gr', value: '+30', flag: 'gr', content: 'Greece (+30)' },
    { key: 'hu', value: '+36', flag: 'hu', content: 'Hungary (+36)' },
    { key: 'is', value: '+354', flag: 'is', content: 'Iceland (+354)' },
    { key: 'ie', value: '+353', flag: 'ie', content: 'Ireland (+353)' },
    { key: 'it', value: '+39', flag: 'it', content: 'Italy (+39)' },
    { key: 'lv', value: '+371', flag: 'lv', content: 'Latvia (+371)' },
    { key: 'li', value: '+423', flag: 'li', content: 'Liechtenstein (+423)' },
    { key: 'lt', value: '+370', flag: 'lt', content: 'Lithuania (+370)' },
    { key: 'lu', value: '+352', flag: 'lu', content: 'Luxembourg (+352)' },
    { key: 'mt', value: '+356', flag: 'mt', content: 'Malta (+356)' },
    { key: 'nl', value: '+31', flag: 'nl', content: 'Netherlands (+31)' },
    { key: 'no', value: '+47', flag: 'no', content: 'Norway (+47)' },
    { key: 'pl', value: '+48', flag: 'pl', content: 'Poland (+48)' },
    { key: 'pt', value: '+351', flag: 'pt', content: 'Portugal (+351)' },
    { key: 'ro', value: '+40', flag: 'ro', content: 'Romania (+40)' },
    { key: 'sk', value: '+421', flag: 'sk', content: 'Slovakia (+421)' },
    { key: 'si', value: '+386', flag: 'si', content: 'Slovenia (+386)' },
    { key: 'es', value: '+34', flag: 'es', content: 'Spain (+34)' },
    { key: 'se', value: '+46', flag: 'se', content: 'Sweden (+46)' },
    { key: 'al', value: '+355', flag: 'al', content: 'Albania (+355)' },
    { key: 'ad', value: '+376', flag: 'ad', content: 'Andorra (+376)' },
    { key: 'am', value: '+374', flag: 'am', content: 'Armenia (+374)' },
    { key: 'by', value: '+375', flag: 'by', content: 'Belarus (+375)' },
    { key: 'ba', value: '+387', flag: 'ba', content: 'Bosnia and Herzegovina (+387)' },
    { key: 'fo', value: '+298', flag: 'fo', content: 'Faroe Islands (+298)' },
    { key: 'ge', value: '+995', flag: 'ge', content: 'Georgia (+995)' },
    { key: 'gi', value: '+350', flag: 'gi', content: 'Gibraltar (+350)' },
    { key: 'mk', value: '+389', flag: 'mk', content: 'North Macedonia (+389)' },
    { key: 'md', value: '+373', flag: 'md', content: 'Moldova (+373)' },
    { key: 'mc', value: '+377', flag: 'mc', content: 'Monaco (+377)' },
    { key: 'me', value: '+382', flag: 'me', content: 'Montenegro (+382)' },
    { key: 'ru', value: '+7', flag: 'ru', content: 'Russia (+7)' },
    { key: 'sm', value: '+378', flag: 'sm', content: 'San Marino (+378)' },
    { key: 'rs', value: '+381', flag: 'rs', content: 'Serbia (+381)' },
    { key: 'ch', value: '+41', flag: 'ch', content: 'Switzerland (+41)' },
    { key: 'tr', value: '+90', flag: 'tr', content: 'Turkey (+90)' },
    { key: 'gb', value: '+44', flag: 'gb', content: 'United Kingdom (+44)' },
    { key: 'ua', value: '+380', flag: 'ua', content: 'Ukraine (+380)' },
];

function getCountryFromPhoneNumber(phoneNumber: string) {
    // Sort the country options by the length of the country code in descending
    // order (country codes are not prefix free :) )
    const sortedCountryOptions = countryOptions.sort((a, b) => b.value.length - a.value.length);

    // Find the country whose code is a prefix of the phone number
    const matchedCountry = sortedCountryOptions.find(country => phoneNumber.startsWith(country.value));

    return matchedCountry || null;
}


@observer
export class TargetPhoneNumberInput extends TargetTextInput {

    /**
     * Gets the selected country based on the phone number field value
     */
    @computed get selectedCountry() {
        if (!this.value) {
            return undefined;
        }

        return getCountryFromPhoneNumber(this.value);
    }


    /**
     * Only enter the typed character if it's allowed according to the formatting
     * rules (only allow digits, spaces and a '+' at the start)
     *
     * @param character The current character to add to the string so far
     * @param currentValue The string so far, to which we want to append the new character
     */
    inputValueIsAllowed = (character: string, currentValue?: string) => {
        const isAllowedChar = [' ', '+'].includes(character) || /\d/.test(character);
        const isPlusAfterStart = character === '+' && currentValue && currentValue.length > 0;
        const isNotPlusAtStart = character !== '+' && (!currentValue || currentValue.length === 0);

        if (!isAllowedChar || isPlusAfterStart || isNotPlusAtStart) {
            return false;
        }

        return true;
    }

    /**
     * On key press, check that the typed key is allowed at the current position
     */
    onKeyPress = (event: KeyboardEvent) => {
        if (!this.inputValueIsAllowed(event.key, this.value)) {
            event.preventDefault();
        }
    }

    onChange(e: Event, { value }) {
        const { name } = this.props;

        value = this.toTarget(value);

        const updatedValue = this.beforeChange(value);
        if (updatedValue === false) {
            return;
        }

        this.setValue(updatedValue, name);
    }

    /**
     * Make sure that the change is allowed
     */
    beforeChange = (value: string | undefined) => {
        if (!value || value.length === 0) {
            return value;
        }

        // If the value now starts with 00 (no "+"), replace the 00 with a +
        if (value.startsWith('00')) {
            value = '+' + value.slice(2);
        }

        let valueSoFar = '';
        for (const character of value) {
            if (!this.inputValueIsAllowed(character, valueSoFar)) {
                showErrorNotification('The phone number input has an invalid format')
                return false;
            }

            valueSoFar += character;
        }

        return value;
    }

    /**
     * Add a plus in case the user focuses the input while it's empty. Purpose
     * is to prevent confusion, since number inputs get blocked as the first
     * character.
     */
    onFocus = () => {
        const { name } = this.props;

        if (!this.value || this.value.length === 0) {
            this.setValue('+', name);
        }
    }

    /**
     * On blur, apply automatic formatting
     */
    onBlur = () => {
        const { name } = this.props;

        const country = getCountryFromPhoneNumber(this.value);
        if (country) {
            const countryCode = country.value;
            const charAfterCountryCode = this.value.slice(countryCode.length);

            // Check if the character after the country code is a space
            if (!charAfterCountryCode.startsWith(' ')) {
                // If not, insert a space after the country code
                this.setValue(`${countryCode} ${charAfterCountryCode.trim()}`, name);
            }
        }
    }

    renderContent(props) {
        return (
            <Input
                value={this.value}
                onChange={this.onChange}
                label={
                    <Label style={{ display: 'flex', alignItems: 'center' }}>
                        {this.selectedCountry?.flag ? (
                            <Flag style={{ marginRight: 0 }} name={this.selectedCountry?.flag ?? 'eu'} />
                        ) : (
                            <Icon style={{ marginRight: 0 }} name='question' />
                        )}
                    </Label>
                }
                onKeyPress={this.onKeyPress}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                {...props}
            />

        );
    }
}
