import React, { Component } from 'react';
import styled from 'styled-components';
import { TargetRadioButtons, Icon } from '@code-yellow/spider';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

export const StyledTargetRadioButtons = styled(TargetRadioButtons)`
    & > div {
        width: auto !important;
    }

    .button {
        padding: 0.58928571em !important;
        color: #fff !important;
    }

    &.compactButton {
        button{
            height: 28.28px!important;
            i{
                margin: 0!important;
            }
        }
    }

    .active {
        cursor: default !important;
        pointer-events: none !important;
        ${({ hl }) => hl === 'green' ? `
            background-color: #4CAF50 !important; // Green
        ` : `
            background-color: #F44336 !important; // Red
        `}
    }
`;

@observer
export default class ActiveButtons extends Component {
    static propTypes = {
        asset: PropTypes.object.isRequired,
        renderType: PropTypes.string.isRequired,
        label: PropTypes.string,
    };

    @observable compactClass = this.props.renderType === 'compact' ? 'compactButton' : '';

    render() {
        const { asset, label } = this.props;

        return (
            <StyledTargetRadioButtons compact
                target={asset}
                name='active'
                options={[
                    { text: <Icon name='close' />, value: false },
                    { text: <Icon name='check' />, value: true },
                ]}
                onChange={(value) => asset.setActive(value)}
                hl={asset.active ? 'green' : 'red'}
                className={this.compactClass}
                label={label}
            />
        )
    }
}
