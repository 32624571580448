import { observable, computed } from 'mobx';
import { omit, isArray } from 'lodash';
import { Model, Store, Casts } from '@code-yellow/spider';
import { PUBLIC_URL } from 'helpers';

import { MetabaseStore } from '@code-yellow/core-metabase';

export class Permission extends Model {
    static backendResourceName = 'permission';

    @observable id = null;
    @observable app = '';
    @observable codename = '';
    @observable permissions = [];
}

export class PermissionStore extends Store {
    Model = Permission;
    static backendResourceName = 'permission';
}

export class Group extends Model {
    static backendResourceName = 'group';

    @observable id = null;
    @observable name = '';
    @observable permissions = this.relation(PermissionStore);
    @observable metabases = this.relation(MetabaseStore);

    // Get the metabases this user can see via their specific assignment
    getGroupMetabases(metabases = []) {
        if (this.metabases) {
            this.metabases.forEach((metabase) => {
                if (!metabases.some(metabaseInList => metabaseInList.id === metabase.id)) {
                    metabases.push(metabase)
                }
            })
        }
        metabases.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        return metabases
    }

}

export class GroupStore extends Store {
    Model = Group;
    static backendResourceName = 'group';
}

export class User extends Model {
    static backendResourceName = 'user';

    @observable id = null;
    @observable email = '';
    @observable firstName = '';
    @observable lastName = '';
    @observable password = '';
    @observable isSuperuser = false;
    @observable avatar = null;
    @observable _avatar = null;
    @observable deleted = false;
    @observable isActive = false;
    @observable dateJoined = null;
    @observable phoneNumber = '';
    @observable telegramUsername = '';

    // TODO: currently hardcoded. Should be a select. Check if user creation still works after changing this
    @observable language = 'en';
    @observable groups = this.relation(GroupStore);
    @observable metabases = this.relation(MetabaseStore);



    getUrl() {
        return `/account/user/${this.id}/edit`;
    }

    casts() {
        return {
            avatar: {
                parse(attr, value) {
                    if (value) {
                        return PUBLIC_URL + value;
                    }

                    return null;
                },
                toJS(attr, value) {
                    return value;
                },
            },
            dateJoined: Casts.datetime,
        }
    }

    saveAvatar() {
        if (!this._avatar) {
            return;
        }

        const data = new FormData();

        data.append('avatar', this._avatar, this._avatar.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.api.post(`${this.url}avatar/`, data, { headers }).then(response => {
            this.parse(response.data);
        });
    }

    @computed
    get fullName() {
        if (this.firstName || this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        return this.email;
    }

    @computed get permissions() {
        const perms = [];

        this.groups.forEach(group =>
            group.permissions.forEach(highLevelPermission => {
                perms.push(`${highLevelPermission.app}.${highLevelPermission.codename}`);

                highLevelPermission.permissions.forEach(p =>
                    perms.push(`${p.name}:${p.scope}`)
                )
            })
        );

        return perms;
    }

    masquerade() {
        return this.api.post(`/user/${this.id}/masquerade/`).then(() => {
            window.location = '/';
        });
    }

    resetRequest(username) {
        return this.api.post('/user/reset_request/', { username });
    }

    changePassword({ passwordOld, passwordNew }) {
        return this.api.put('/user/change_password/', {
            old_password: passwordOld,
            new_password: passwordNew,
        });
    }

    resetPassword({ id, password, resetCode }) {
        return this.api
            .put(`/user/${id}/reset_password/`, {
                password,
                reset_code: resetCode,
            })
            .then(() => {
                window.location = '/';
            });
    }

    hasPermission(permissions) {
        if (this.isSuperuser) {
            return true;
        }
        if (!isArray(permissions)) {
            return this.permissions.includes(permissions);
        }
        return this.permissions.some(groupName =>
            permissions.includes(groupName)
        );
    }

    @computed
    get groupNames() {
        return this.groups.map(group => group.name)
    }

    @computed
    get isDriver() {
        return this.groups.map(g => g.name).includes('driver') && this.groups.length === 1;
    }

    inGroup(groups) {
        if (this.isSuperuser) {
            return true;
        }
        if (!isArray(groups)) {
            return this.groupNames.includes(groups);
        }
        return this.groupNames.some(groupName => groups.includes(groupName));
    }

    toBackend(options = {}) {
        return omit(super.toBackend(options), 'password', 'active');
    }



    // Get the metabases this user can see via their specific assignment
    getUserMetabases(metabases = []) {
        if (this.metabases) {
            this.metabases.forEach((metabase) => {
                if (!metabases.some(metabaseInList => metabaseInList.id === metabase.id)) {
                    metabases.push(metabase)
                }
            })
        }
        metabases.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        return metabases
    }

    // Method which, for the current user, returns a list of metabases to be shown for this user
    // This list does not contain duplicate metabase dashboards (i.e. someone has a dashboard both via
    // that users group and as an individual user) and trips the dashboard in the correct order
    getMetabases() {
        let metabases = this.getUserMetabases()
        if (this.groups) {
            this.groups.forEach((group) => {
                metabases = group.getGroupMetabases(metabases)
            })
        }
        return metabases
    }
}

export class UserStore extends Store {
    Model = User;
    static backendResourceName = 'user';
}
