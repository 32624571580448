import { TargetSelect } from '@code-yellow/spider';
import { uniqueCountries } from 'countries';

export class TargetCountrySelect extends TargetSelect {
    static defaultProps = {
        ...TargetSelect.defaultProps,
        search: true,
        options: uniqueCountries.map(countryItem => ({
            value: countryItem.code,
            flag: countryItem.code.toLowerCase(),
            text: countryItem.code
        })),
    };
}
